import { useNavigate } from 'react-router-dom'
import Cookies from "js-cookie"
import React from "react"
import { useContext } from 'react'
import { GlobalContext } from '../Context/context'
import { useEffect } from 'react'
import {truncateString, convertRupiah,
    computeTotalWeight,
    computeTotalProduksi,
    extractNamaKapal,
    extractTotalVolume,
    extractTotalPayment,
    computeTaxProduksi,
    generateUUID,
    extractTimeStamp,
    statusConvertion,mappingHargaAcuan,nilaiProduksi} from '../Utils/utils'
import { useParams } from 'react-router-dom'
import { postNotification} from '../Api/iot'
import style from '../styles.css';
import {Footer} from "../Footer/footer"

export const Dashboard = () => {
    let navigate = useNavigate()
    return (
        <>
            <div>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <link href="/dist/tailwind.css" rel="stylesheet" />
                <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.0/font/bootstrap-icons.css" />
                <span className="absolute text-white text-4xl top-5 left-4 cursor-pointer" onclick="openSidebar()">
                    <i className="bi bi-filter-left px-2 bg-gray-900 rounded-md" />
                </span>
                <div className="sidebar fixed top-0 bottom-0 lg:left-0 p-2 w-[300px] overflow-y-auto text-center bg-gray-900">
                    <div className="text-gray-100 text-xl">
                    <div className="p-2.5 mt-1 flex items-center">
                        <i className="bi bi-app-indicator px-2 py-1 rounded-md bg-blue-600" />
                        <h1 className="font-bold text-gray-200 text-[15px] ml-3">Dashboard Menu</h1>
                        <i className="bi bi-x cursor-pointer ml-28 lg:hidden" onclick="openSidebar()" />
                    </div>
                    <div className="my-2 bg-gray-600 h-[1px]" />
                    </div>

                    {/* section Home */}
                    <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white"
                        onClick={() => {
                            navigate('/')
                        }}
                    >
                    <i className="bi bi-house-door-fill" />
                    <span className="text-[15px] ml-4 text-gray-200 font-bold" >Home</span>
                    </div>

                    <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white"
                    onClick={() => {
                        navigate('/dashboard')
                    }}
                    >
                    <i className="bi bi-bar-chart-fill" />
                    <span className="text-[15px] ml-4 text-gray-200 font-bold">Summary</span>
                    </div>
                    
                    
                    {/* MANAGE JOBS DATA */}
                    <div className="my-4 bg-gray-600 h-[1px]" />
                    <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white" onclick="dropdown()">
                    <i className="bi bi-activity" />
                    <div className="flex justify-between w-full items-center">
                        <span className="text-[15px] ml-4 text-gray-200 font-bold">Akademik Dosen</span>
                        <span className="text-sm rotate-180" id="arrow">
                        <i className="bi bi-chevron-down" />
                        </span>
                    </div>
                    </div>
                    <div className="text-left text-sm mt-2 w-4/5 mx-auto text-gray-200 font-bold" id="submenu">
                    <h1 className="cursor-pointer p-2 hover:bg-blue-600 rounded-md mt-1"
                    onClick={() => {
                        navigate('/dashboard/list-mahasiswa-bimbingan')
                    }}>
                        List Mahasiswa Bimbingan
                    </h1>
                    <h1 className="cursor-pointer p-2 hover:bg-blue-600 rounded-md mt-1"
                    onClick={() => {
                        navigate('/dashboard/list-job-vacancy/create')
                    }}
                    >
                        List Mata Kuliah Pengampu
                    </h1>

                    <h1 className="cursor-pointer p-2 hover:bg-blue-600 rounded-md mt-1"
                    onClick={() => {
                        navigate('/dashboard/list-job-vacancy/create')
                    }}
                    >
                        RPS Mata kuliah
                    </h1>
                    </div>


                    {/* akademik */}
                    <div className="my-4 bg-gray-600 h-[1px]" />
                    <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white" onclick="dropdown()">
                    <i className="bi bi-book" />
                    <div className="flex justify-between w-full items-center">
                        <span className="text-[15px] ml-4 text-gray-200 font-bold">Akademik</span>
                        <span className="text-sm rotate-180" id="arrow">
                        <i className="bi bi-chevron-down" />
                        </span>
                    </div>
                    </div>
                    <div className="text-left text-sm mt-2 w-4/5 mx-auto text-gray-200 font-bold" id="submenu">

                    <h1 className="cursor-pointer p-2 hover:bg-blue-600 rounded-md mt-1"
                        onClick={() => {
                            navigate('/dashboard/change-password')
                        }}>
                            Mata Kuliah Program Studi
                        </h1>

                        <h1 className="cursor-pointer p-2 hover:bg-blue-600 rounded-md mt-1"
                        onClick={() => {
                            navigate('/dashboard/change-password')
                        }}>
                            Jadwal Perkuliahan
                        </h1>

                        
                        <h1 className="cursor-pointer p-2 hover:bg-blue-600 rounded-md mt-1"
                        onClick={() => {
                            navigate('/dashboard/list-job-vacancy/create')
                        }}
                        >
                            Nilai Akademik Mahasiswa
                        </h1>

                        <h1 className="cursor-pointer p-2 hover:bg-blue-600 rounded-md mt-1"
                        onClick={() => {
                            navigate('/dashboard/list-job-vacancy/create')
                        }}
                        >
                            Rencana Pembelajaran Semester
                        </h1>

                        <h1 className="cursor-pointer p-2 hover:bg-blue-600 rounded-md mt-1"
                        onClick={() => {
                            navigate('/dashboard/list-kurikulum')
                        }}
                        >
                            Kurikulum Program Studi
                        </h1>




                    </div>

                    {/* master data */}
                    <div className="my-4 bg-gray-600 h-[1px]" />
                    <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white" onclick="dropdown()">
                    <i className="bi bi-clipboard2-data"></i>
                    <div className="flex justify-between w-full items-center">
                        <span className="text-[15px] ml-4 text-gray-200 font-bold">Master Data</span>
                        <span className="text-sm rotate-180" id="arrow">
                        <i className="bi bi-database-fill-gear"></i>
                        </span>
                    </div>
                    </div>
                    <div className="text-left text-sm mt-2 w-4/5 mx-auto text-gray-200 font-bold" id="submenu">
                        

                    <h1 className="cursor-pointer p-2 hover:bg-blue-600 rounded-md mt-1"
                     onClick={() => {
                        navigate('/dashboard/change-password')
                    }}>
                        List Kelas
                    </h1>
                    <h1 className="cursor-pointer p-2 hover:bg-blue-600 rounded-md mt-1"
                     onClick={() => {
                        navigate('/dashboard/change-password')
                    }}>
                        List Matakuliah
                    </h1>

                    <h1 className="cursor-pointer p-2 hover:bg-blue-600 rounded-md mt-1"
                     onClick={() => {
                        navigate('/dashboard/change-password')
                    }}>
                        List Mahasiswa
                    </h1>

                    <h1 className="cursor-pointer p-2 hover:bg-blue-600 rounded-md mt-1"
                     onClick={() => {
                        navigate('/dashboard/list-dosen')
                    }}>
                        List Dosen Prodi
                    </h1>
                    </div>


                    {/* PROFILE */}
                    <div className="my-4 bg-gray-600 h-[1px]" />
                    <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white" onclick="dropdown()">
                    <i className="bi bi-person-fill" />
                    <div className="flex justify-between w-full items-center">
                        <span className="text-[15px] ml-4 text-gray-200 font-bold">Profile</span>
                        <span className="text-sm rotate-180" id="arrow">
                        <i className="bi bi-chevron-down" />
                        </span>
                    </div>
                    </div>
                    <div className="text-left text-sm mt-2 w-4/5 mx-auto text-gray-200 font-bold" id="submenu">
                        

                    <h1 className="cursor-pointer p-2 hover:bg-blue-600 rounded-md mt-1"
                     onClick={() => {
                        navigate('/dashboard/change-password')
                    }}>
                        Biodata
                    </h1>
                    <h1 className="cursor-pointer p-2 hover:bg-blue-600 rounded-md mt-1"
                     onClick={() => {
                        navigate('/dashboard/change-password')
                    }}>
                        Change Password
                    </h1>
                    </div>

                    <div className="p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-blue-600 text-white">
                        <i className="bi bi-box-arrow-in-right" />
                        <span className="text-[15px] ml-4 text-gray-200 font-bold"
                        onClick={() => {
                            Cookies.remove('token')
                            navigate('/login')
                        }}
                        >Logout</span>
                    </div>
                </div>
            </div>
        </>
    )
}

// export const DashboardSummary = () => {
//     const { state, handler } = useContext(GlobalContext)
//     const {
//         dataStudent
//     } = state 

//     const {
//       handleFetchStudent,
//     } = handler

//     useEffect( () => {
//         handleFetchStudent()
//     }, [])  

//     let student = []
//     for (let index = 0; index < dataStudent.length; index++) {
//         const element = dataStudent[index];
//         student.push(element)
//     }

//     let studentNum = dataStudent?.length

//     return (
//         <>
//             <section className="max-w-screen-xl bg-green-300 dark:bg-gray-800 px-4 py-12 mx-auto sm:py-16 sm:px-6 lg:px-8 lg:py-20">
//                 <div className="max-w-4xl mx-auto text-center">
//                     <h2 className="text-3xl font-extrabold leading-9 text-white sm:text-4xl sm:leading-10">
//                     Education Going Digital!
//                     </h2>
//                 </div>
//                 <div className="mt-10 text-center justify-center">
//                     <div className="mt-10 sm:mt-0">
//                     <div className='flex flex-x-2 justify-center mt-10 sm:mt-0'>
                        

//                     </div>

//                     <div>
//                         <p className="text-8xl font-extrabold leading-none text-white">
//                             {studentNum}
//                         </p>
//                         <p className="mt-2 text-base font-medium leading-6 text-blue">
//                             Total Mahasiswa
//                         </p>
//                     </div>
                    

//                     {/* <p className="text-8xl font-extrabold leading-none text-white">
//                         {closedVacan}
//                     </p> */}
//                     {/* <p className="mt-2 text-base font-medium leading-6 text-red">
//                         Total Closed Vacancy
//                     </p> */}

//                     </div>
//                 </div>
//             </section>
//             <div className='justify-center'>

//             </div>
            
//         </>
//     )
// }

// cek data (edit & delete)
export const DashboardListData = () => {
    const { state, handler } = useContext(GlobalContext)
    const {
        jobs,
        fetchStatus,
        inputSearch
    } = state 

    const {
      handleFetch,
      HandelDeleteJob,
      handleEdit,
      handleSearch,
      handleChangeSearch,
    } = handler

    useEffect( () => {
        if (fetchStatus === true) {
            handleFetch()
        }
    }, [fetchStatus, handleFetch])  

    const JobList = () => {
        return (
          <div className="mt-10 ml-80 mr-10 mb-30">
               <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-white-700 uppercase bg-purple-400 dark:bg-purple-700 text-white-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    NO
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Title
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Job Desc
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Job Req
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Job Type
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Job Tenure
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Job Status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Company Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Company Image Url
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Company City
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Salary Min
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Salary Max
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                              jobs?.map((user, idx) => {
                                return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={idx}>
                                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{idx+1}</td>
                                  <td>{user.title}</td>
                                  <td>{truncateString(user.job_description, 30) }</td>
                                  <td>{truncateString(user.job_qualification, 30)}</td>
                                  <td>{user.job_type}</td>
                                  <td>{user.job_tenure}</td>
                                  <td >{statusConvertion(user.job_status)}</td>
                                  <td>{user.company_name}</td>
                                  <td>{user.company_image_url}</td>
                                  <td>{user.company_city}</td>
                                  <td>{convertRupiah(user.salary_min)}</td>
                                  <td>{convertRupiah(user.salary_max)}</td>
                                  <td className="flex space-x-4">
                                  {
                                      <button value={user.id} 
                                      onClick={handleEdit}
                                      className="bg-yellow-300 
                                        hover:bg-yellow-700 text-white font-bold py-2 px-4 border border-yellow-700 rounded mt-2">
                                      Lihat
                                      </button>
                                    }

                                    {
                                      <button 
                                        onClick={HandelDeleteJob} 
                                        value={user.id} 
                                        className="bg-red-300 hover:bg-red-700 
                                        text-white font-bold py-2 px-4 border border-red-700 rounded mt-2">
                                        Delete
                                      </button>
                                    }
                                  </td>
                                  
                                </tr>
                              })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    return (
        <>
            <form className="mt-10 ml-80 mr-10"
                onSubmit={handleSearch}
            >   
                <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" 
                        iewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                    </div>

                    <input type="search" id="default-search" 
                    className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 
                        focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
                        dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                    placeholder="Search Mahasiswa" 
                    name="title"
                    onChange={handleChangeSearch} 
                    value={inputSearch.title}
                    />

                    <button type="submit" className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 
                        focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 
                        dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                </div>
            </form>

            <br></br>
            {/* <div className="flex flex-x-2 justify-center">
                <FilterByStatus/>
                <FilterByCity/>
                <FilterByMinSalary/>
            </div> */}
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            
            <JobList/>

            
        </>
    )
}

// add data
export const DashboardAddData = () => {
    const { state, handler } = useContext(GlobalContext)
    const {
        dataForm
    } = state 

    const {
      handleInput,
      handleSubmit
    } = handler


    return (
        <>
            <div id="main_add" className='ml-80 mt-10 mr-40'>
                <h1><b>Add New Data Here!</b></h1>
                    <div>
                <form className="" onSubmit={handleSubmit}  >
                    <br></br>
                    <br></br>
                    <div id="data-gambar">
                        <p className='border-b-2'>Url Gambar company</p>
                        <br/>
                        <br/>
                        <div>
                            <label htmlFor="small-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                <b>Company Image URL</b></label>
                            <input type="text" id="small-input"
                                name='company_image_url'
                                onChange={handleInput} 
                                value={dataForm.company_image_url}
                                placeholder='masukkan image company url..'
                                className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>
                        <br/>
                    </div>
                    
                    
                    <div id="data-game">
                        <p className='border-b-2'> Data Job</p>
                        <br></br>
                        <div>
                            <label htmlFor="small-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            <b>Title</b>
                                </label>
                            <input type="text" id="small-input"
                                required
                                name='title'
                                onChange={handleInput} 
                                value={dataForm.title}
                                placeholder='masukkan title pekerjaan..'
                                className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>
                        <br/>

                        <div>
                            <label htmlFor="small-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            <b>Job Desc</b>
                                </label>
                            <input type="text"
                                required
                                name='job_description'
                                onChange={handleInput} 
                                value={dataForm.job_description}
                                placeholder='masukkan job desc..'
                                id="small-input" className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>
                        <br/>


                        <div>
                            <label htmlFor="small-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            <b>Job Req</b>
                                </label>
                            <input type="text" id="small-input" 
                                required
                                name='job_qualification'
                                onChange={handleInput} 
                                value={dataForm.job_qualification}
                                placeholder='masukkan job req..'
                                className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>
                        <br/>

                        <div>
                            <label htmlFor="small-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            <b>Job Type</b>
                                </label>
                            <input type="text" 
                                required
                                name='job_type'
                                onChange={handleInput} 
                                value={dataForm.job_type}
                                placeholder='masukkan job tipe..'
                                id="small-input" className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>
                        <br/>

                        <div>
                            <label htmlFor="small-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            <b>Job Tenure</b>
                                </label>
                            <input type="text" 
                                required
                                name='job_tenure'
                                onChange={handleInput} 
                                value={dataForm.job_tenure}
                                placeholder='masukkan job tenure..'
                            id="small-input" className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>
                        <br/>

                        <div>
                            <label htmlFor="small-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            <b>Status</b>
                                </label>
                            <input type="number"
                                required
                                onChange={handleInput} 
                                value={dataForm.job_status}
                                name='job_status'
                                min="0"
                                max={1}
                                placeholder='masukkan status job..'
                                id="small-input" className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>
                        <br/>

                        <div>
                            <label htmlFor="small-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            <b>Company Name</b>
                                </label>
                            <input type="text" 
                                required
                                name='company_name'
                                onChange={handleInput} 
                                value={dataForm.company_name}
                                placeholder='masukkan company name..'
                                id="small-input" className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>
                        <br/>

                        <div>
                            <label htmlFor="small-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            <b>Company City</b>
                                </label>
                            <input type="text" 
                                required
                                name='company_city'
                                onChange={handleInput} 
                                value={dataForm.company_city}
                                placeholder='masukkan company city..'
                                id="small-input" className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>
                        <br/>
                    </div>

                    <div id="device-type">
                        <p className='border-b-2'>Salary</p>
                        <br/>
                        <br/>
                        <div>
                            <label htmlFor="small-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            <b>Salary Min</b>
                                </label>
                            <input type="number"
                                onChange={ handleInput} 
                                value={dataForm.salary_min}
                                required
                                name='salary_min'
                                min="0"
                                id="small-input" className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>
                        <br/>

                        <div>
                            <label htmlFor="small-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            <b>Salary Max</b>
                                </label>
                            <input type="number" 
                                onChange={handleInput} 
                                value={dataForm.salary_max}
                                required
                                name='salary_max'
                                min="0"
                                id="small-input" className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                        </div>
                        <br/>
                    </div>
                    
                    {/* button  */}
                    <div className="flex items-center justify-between">
                        <button  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                        type={'submit'}>
                            Submit
                        </button>
                    </div>

                    <br/>
                    </form>
                </div>


            </div>
        </>
    )
}

const PaginationTest = () =>{
    return (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
  <div className="flex flex-1 justify-between sm:hidden">
    <a href="#" className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Previous</a>
    <a href="#" className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Next</a>
  </div>
  <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
    <div>
      <p className="text-sm text-gray-700">
        Showing
        <span className="font-medium">1</span>
        to
        <span className="font-medium">10</span>
        of
        <span className="font-medium">97</span>
        results
      </p>
    </div>
    <div>
      <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
        <a href="#" className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
          <span className="sr-only">Previous</span>
          <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
          </svg>
        </a>
        {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
        <a href="#" aria-current="page" className="relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">1</a>
        <a href="#" className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">2</a>
        <a href="#" className="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">3</a>
        <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>
        <a href="#" className="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">8</a>
        <a href="#" className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">9</a>
        <a href="#" className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">10</a>
        <a href="#" className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
          <span className="sr-only">Next</span>
          <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
          </svg>
        </a>
      </nav>
    </div>
  </div>
</div>

    )
}



// DashboardListMahasiswa
export const DashboardListMahasiswa = () => {
    const { state, handler } = useContext(GlobalContext)
    const {
        dataStudent,
        inputSearch,
        fetchStatusStudent
    } = state 

    const {
        handleFetchStudent,
        handleSearch,
        handleChangeSearch
    } = handler

    useEffect( () => {
        if (fetchStatusStudent === true) {
            handleFetchStudent()
        }
    }, [handleFetchStudent, fetchStatusStudent])  


    const MahasiswaList = () => {
        return (
          <div className="mt-10 ml-80 mr-10 mb-30">
               <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-white-700 uppercase bg-purple-400 dark:bg-purple-700 text-white-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    NO
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    NIM
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Name
                                </th> 
                                <th scope="col" className="px-6 py-3">
                                    Affiliation
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Study Program
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Degree
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Email
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Phone Number
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                              dataStudent?.map((user, idx) => {
                                return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={idx}>
                                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{idx+1}</td>
                                  <td>{user.id}</td>
                                  <td>{user.first_name}  {user.middle_name} {user.last_name}</td>
                                  <td>{user.affiliation}</td>
                                  <td>{user.study_program}</td>
                                  <td>{user.degree}</td>
                                  <td>{user.email}</td>
                                  <td>{user.phone_number}</td>

                                  <td className="flex space-x-4">
                                  {
                                      <button value={user.id} 
                                    //   onClick={handleEdit}
                                      className="bg-blue-300 
                                        hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded mt-2">
                                      Lihat
                                      </button>
                                    }

                                    {/* {
                                      <button 
                                        // onClick={HandelDeleteJob} 
                                        value={user.id} 
                                        className="bg-red-300 hover:bg-red-700 
                                        text-white font-bold py-2 px-4 border border-red-700 rounded mt-2">
                                        Delete
                                      </button>
                                    } */}
                                  </td>
                                  
                                </tr>
                              })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    return (
        <>
            <form className="mt-10 ml-80 mr-10"
                onSubmit={handleSearch}
            >   
                <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" 
                        iewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                    </div>

                    <input type="search" id="default-search" 
                    className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 
                        focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
                        dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                    placeholder="Search Mahasiswa" 
                    name="title"
                    onChange={handleChangeSearch} 
                    value={inputSearch.title}
                    />

                    <button type="submit" className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 
                        focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 
                        dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                </div>
            </form>

            <br></br>
            {/* <div className="flex flex-x-2 justify-center">
                <FilterByStatus/>
                <FilterByCity/>
                <FilterByMinSalary/>
            </div> */}

            <MahasiswaList/>
            <br/>
            <br></br>
            
            {/* <Pagination count={10} /> */}
            <PaginationTest/>

            
        </>
    )
}

// DashboardListDosen
export const DashboardListDosen = () => {
    const { state, handler } = useContext(GlobalContext)
    const {
        dataLecturer,
        inputSearch,
        fetchStatusLecturer
    } = state 

    const {
        handleFetchLecturer,
        handleSearch,
        handleChangeSearch
    } = handler

    useEffect( () => {
        if (fetchStatusLecturer === true) {
            handleFetchLecturer()
        }
    }, [handleFetchLecturer, fetchStatusLecturer])  


    const DosenList = () => {
        return (
          <div className="mt-10 ml-80 mr-10 mb-30">
               <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-white-700 uppercase bg-purple-400 dark:bg-purple-700 text-white-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    NO
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Name
                                </th> 
                                <th scope="col" className="px-6 py-3">
                                    Affiliation
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Study Program
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Academic Position
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Email
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Phone Number
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                              dataLecturer?.map((user, idx) => {
                                return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={idx}>
                                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{idx+1}</td>
                                  <td>{user.id}</td>
                                  <td>{user.first_name}  {user.middle_name} {user.last_name}</td>
                                  <td>{user.affiliation}</td>
                                  <td>{user.study_program}</td>
                                  <td>{user.academic_position}</td>
                                  <td>{user.email}</td>
                                  <td>{user.phone_number}</td>

                                  <td className="flex space-x-4">
                                  {
                                      <button value={user.id} 
                                    //   onClick={handleEdit}
                                      className="bg-blue-300 
                                        hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded mt-2">
                                      Lihat
                                      </button>
                                    }

                                    {/* {
                                      <button 
                                        // onClick={HandelDeleteJob} 
                                        value={user.id} 
                                        className="bg-red-300 hover:bg-red-700 
                                        text-white font-bold py-2 px-4 border border-red-700 rounded mt-2">
                                        Delete
                                      </button>
                                    } */}
                                  </td>
                                  
                                </tr>
                              })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    return (
        <>
            <form className="mt-10 ml-80 mr-10"
                onSubmit={handleSearch}
            >   
                <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" 
                        iewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                    </div>

                    <input type="search" id="default-search" 
                    className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 
                        focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
                        dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                    placeholder="Search Dosen" 
                    name="title"
                    onChange={handleChangeSearch} 
                    value={inputSearch.title}
                    />

                    <button type="submit" className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 
                        focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 
                        dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
                </div>
            </form>

            <br></br>
            {/* <div className="flex flex-x-2 justify-center">
                <FilterByStatus/>
                <FilterByCity/>
                <FilterByMinSalary/>
            </div> */}

            <DosenList/>
            <br/>
            <br></br>
            
        </>
    )
}

export const DashboardKurikulum = () => {
   
    const Template = () => {
        return (
            <div className="mt-10 ml-80 mr-10 mb-30">
                 <div className="relative overflow-x-auto">
                    <h1>KURIKULUM </h1>


                    <br></br>
                    <br></br>
                      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                          <thead className="text-xs text-white-700 uppercase bg-purple-400 dark:bg-purple-700 text-white-400">
                              <tr>
                                  <th scope="col" className="px-6 py-3">
                                      NO
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                      Jumlah Matakuliah
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                      Nama Kurikulum
                                  </th> 
                                  <th scope="col" className="px-6 py-3">
                                      Rata-rata SKS Semester
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                      SKS wajib
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                      SKS optional
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                      Jenis Program Studi
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                      Aksi
                                  </th>
                       
                              </tr>
                          </thead>
                          <tbody>
                             
                          </tbody>
                      </table>
                  </div>
              </div>
          )
    }

    return (
        <div>
            <h1> KURIKULUM</h1>
            <Template/>
        </div>
   
    )
}

export const DashboardRPS = () => {
    const Template = () => {
        return (
            <div className="mt-10 ml-80 mr-10 mb-30">
                 <div className="relative overflow-x-auto">
                    <h1>Rencana Pembelajaran Semester </h1>


                    <br></br>
                    <br></br>
                      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                          <thead className="text-xs text-white-700 uppercase bg-purple-400 dark:bg-purple-700 text-white-400">
                              <tr>
                                  <th scope="col" className="px-6 py-3">
                                      NO
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                      Kode Matakuliah
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                      Nama Matakuliah
                                  </th> 
                                  <th scope="col" className="px-6 py-3">
                                      SKS
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                      Kelas
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                      Jumlah Mahasiswa
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                      Dosen Pengampu
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                      Upload RPS / Download Template
                                  </th>
                       
                              </tr>
                          </thead>
                          <tbody>
                             
                          </tbody>
                      </table>
                  </div>
              </div>
          )
    }

    return (
        <div>
            <h1> KURIKULUM</h1>
            <Template/>
        </div>
   
    )
}

// ListTransactions
export const ListTransactions = () => {
    const attr = {
        marginTop : "5%",
        marginLeft: "30px",
        marginRight : "30px"
    }

    const { state, handler } = useContext(GlobalContext)
    const {
        dataTxPool,
        fetchStatusTxPool
    } = state 
    const {
        handleFetchTxPool,
        handleHitung,
    } = handler

    useEffect( () => {
        if (fetchStatusTxPool === true) {
            handleFetchTxPool()
        }
    }, [handleFetchTxPool, fetchStatusTxPool])  

    let resp = dataTxPool?.data?.data

    let identifier = ""
    if (resp?.transactions.length > 0) {
        identifier = resp?.transactions[0].value.ship_code
    }
    const DosenList = () => {
        return (
            
          <div className="" style={attr}>
                <br></br>
                <br></br>
                <br></br>
                <h1><b>DATA PENANGKAPAN IKAN</b></h1>
                <br></br>
               <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Jenis Ikan
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Volume (KG)
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Harga Acuan/Kg
                                </th> 
                                {/* <th scope="col" className="px-6 py-3">
                                    Nilai Produksi
                                </th> */}
                            </tr>
                        </thead>

                        <tbody>
                            {
                              resp?.transactions.map((val, idx) => {
                                return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={idx}>
                                  <td>{val.value.fish_type}</td>
                                  <td>{val.value.weight}</td>
                                  <td>{convertRupiah(mappingHargaAcuan(val.value.fish_type))}</td>
                                  
                                </tr>
                              })
                            }
                        </tbody>
                    </table>
                </div>
                
                <br></br>
                <br></br>
                <br></br>
                
                <form onSubmit={handleHitung}>
                <div className="flex flex-col md:flex-row">
                    <div className="w-64 mx-2 font-bold h-6 mt-3 text-gray-800" />
                    <div className="flex-1 flex flex-col md:flex-row">
                        {
                        Cookies.get('role') == "admin" &&
                        <button type="submit" 

                            className="text-sm  mx-2 w-32  focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer 
                                hover:bg-teal-700 hover:text-teal-100 
                                bg-teal-100 
                                text-teal-700 
                                border duration-200 ease-in-out 
                                border-teal-600 transition bttn-hitung"
                            >
                            HITUNG
                        </button>
                        }
                 </div>
            </div>

                </form>
               
            </div>
        )
    }

    return (
        <>
            {
            (Cookies.get('sh|ip_code') == identifier |  Cookies.get('role') == 'admin') &&
            <DosenList/>
            }
            <br/>
            <br></br>
        </>
    )
}

// ListTransactions
export const ListVerifyTransaction = () => {
    const attr = {
        marginTop : "5%",
        marginLeft: "30px",
        marginRight : "30px"
    }
    
    const { state, handler } = useContext(GlobalContext)
    const {
        dataTxPool,
        fetchStatusTxPool
    } = state 
    const {
        handleFetchTxPool,
        handleVerify,
    } = handler

    useEffect( () => {
        if (fetchStatusTxPool === true) {
            handleFetchTxPool()
        }
    }, [handleFetchTxPool, fetchStatusTxPool]) 

    let resp = dataTxPool?.data?.data

    const DosenList = () => {
        return (
        
          <div className="" style={attr}>
                <br></br>
                <br></br>
                <br></br>
                <h1><b>DATA PENANGKAPAN IKAN</b></h1>
                <br></br>
               <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Jenis Ikan
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Volume (KG)
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Harga Acuan/Kg
                                </th> 
                                <th scope="col" className="px-6 py-3">
                                    Nilai Produksi
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                              resp?.transactions.map((val, idx) => {
                                return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={idx}>
                                  <td>{val.value.fish_type}</td>
                                  <td>{val.value.weight}</td>
                                  <td>{convertRupiah(mappingHargaAcuan(val.value.fish_type))}</td>
                                  <td>{convertRupiah(nilaiProduksi(val.value.weight, mappingHargaAcuan(val.value.fish_type)))}</td>
                                  
                                </tr>
                              })
                            }
                        </tbody>
                    </table>
                </div>
                
                <br></br>
                <br></br>
                <br></br>
                
                <div className='summary-calc'>
                    <h1>Total Berat : {computeTotalWeight(resp)}</h1>
                    <h1>Total Nilai Produksi : {convertRupiah(computeTotalProduksi(resp))}</h1>
                    <br></br>
                    <h1>Indeks Tarif : 10%</h1>
                    <h1>Besaran Pungutan Hasil Perikanan : {convertRupiah(computeTaxProduksi(computeTotalProduksi(resp)))}</h1>
                </div>
                <br></br>
                <form
                onSubmit={handleVerify}>

                <div className="flex flex-col md:flex-row">
                    <div className="w-64 mx-2 font-bold h-6 mt-3 text-gray-800" />
                    <div className="flex-1 flex flex-col md:flex-row">
                        <button type="submit" 

                        className="text-sm  mx-2 w-32  focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer 
                            hover:bg-teal-700 hover:text-teal-100 
                            bg-teal-100 
                            text-teal-700 
                            border duration-200 ease-in-out 
                            border-teal-600 transition bttn-verify"
                            
                        >
                        VERIFY
                        </button>
                    </div>
                </div>
                    
                </form>

            </div>
        )
    }

    return (
        <>
            <DosenList/>
            <br/>
            <br></br>
        </>
    )
}

// ListVerifiedTransactions 
export const ListVerifiedTransactions = () => {
    const attr = {
        marginTop : "5%",
        marginLeft: "30px",
        marginRight : "30px"
    }

    const { state, handler } = useContext(GlobalContext)
    const {
        dataChain,
        fetchStatusChain
    } = state 

    const {
        handleFetchChain,
    } = handler

    useEffect( () => {
        if (fetchStatusChain === true) {
            handleFetchChain()
        }
    }, [handleFetchChain, fetchStatusChain])  

    let resp = dataChain?.data?.data?.chain
    // TODO : mapping group by ship

    const DosenList = () => {
        return (
            
          <div className="" style={attr}>
                <br></br>
                <br></br>
                <br></br>
                <h1><b>RIWAYAT PENANGKAPAN IKAN</b></h1>
                <br></br>
               <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Nama Kapal
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Total Volume (KG)
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Total Pembayaran
                                </th> 
                                <th scope="col" className="px-6 py-3">
                                    Kode Billing
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Tanggal Tercatat
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Tanggal Jatuh Tempo
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status Pembayaran
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                // skip index genesis
                              resp?.filter((_, idx) => idx !== 0).map((val, idx) => {
                                // ambil value transaction nanti tinggal mapping sendiri
                                return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={idx}>
                                  {/* <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{idx+1}</td> */}
                                  <td>{extractNamaKapal(val)}</td>
                                  <td>{extractTotalVolume(val)}</td>
                                  <td>{convertRupiah(extractTotalPayment(val))}</td>
                                  <td>{generateUUID()}</td>
                                  <td>{extractTimeStamp(val)}</td>
                                  <td>20 MAY 2026</td>
                                  <td>BELUM BAYAR</td>
                                  {/* <td>{user.updated_at}</td> */}
                                  
                                </tr>
                              })
                            }
                        </tbody>
                    </table>
                </div>
                
                <br></br>
                <br></br>
                <br></br>
                <div className="flex flex-col md:flex-row">
                    <div className="w-64 mx-2 font-bold h-6 mt-3 text-gray-800" />
            </div>

            </div>
        )
    }

    return (
        <>
            <DosenList/>
            <br/>
            <br></br>
        </>
    )
}



// DashboardShipSummary - get summary data based on shipcode or userid
export const DashboardShipSummary = () => {
    const attr = {
        marginTop : "5%",
        marginLeft: "30px",
        marginRight : "30px"
    }

    const { state, handler } = useContext(GlobalContext)
    const {
        dataShip,
        fetchStatusShip
    } = state 

    const {
        handleFetchSummaryShip,
    } = handler

    useEffect( () => {
        if (fetchStatusShip === true) {
            handleFetchSummaryShip()
        }
    }, [handleFetchSummaryShip,  fetchStatusShip])  

    let result = dataShip?.data?.data

    return (
        <>
            {/* summary block */}
            <div className="kuota">
                <h1>{result?.kuota}</h1>
            </div>
        
            <div className="realisasi">
                <h1>{result?.realkuota}</h1>
            </div>
       

            <div className="sisa-kuota">
                <h1>{result?.remainkuota}</h1>
            </div>


            {/* block kapal  */}
            <div className="ship-code">
                <h1>KODE KAPAL : {Cookies.get('ship_code')} </h1>
            </div>
            
            <div className="ship-zone">
                <h1>ZONA TERDAFTAR : {Cookies.get('zone')}</h1>
            </div>

            
            <h1></h1>
            {/* <DosenList/> */}
            <br/>
            <br></br>
        </>
    )
}


// DashboardListStudentProdi
export const DashboardListStudentProdi = () => {
    const attr = {
        marginTop : "5%",
        marginLeft: "30px",
        marginRight : "30px"
    }

    const { state, handler } = useContext(GlobalContext)
    const {
        dataStudentProdi,
        fetchStatusStudentProdi
    } = state 

    const {
        handleFetchStudentProdi,
    } = handler

    useEffect( () => {
        if (fetchStatusStudentProdi === true) {
            handleFetchStudentProdi()
        }
    }, [handleFetchStudentProdi, fetchStatusStudentProdi])  

    const DosenList = () => {
        return (
          <div className="" style={attr}>
               <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    NO
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    NIM
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Name
                                </th> 
                                <th scope="col" className="px-6 py-3">
                                    Affiliation
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Study Program
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Degree
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Email
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Phone Number
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    ID Prodi
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    SKS Taken
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    IPK 
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                              dataStudentProdi?.map((user, idx) => {
                                return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={idx}>
                                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{idx+1}</td>
                                  <td>{user.student.id}</td>
                                  <td>{user.student.first_name}  {user.student.middle_name} {user.student.last_name}</td>
                                  <td>{user.student.affiliation}</td>
                                  <td>{user.student.study_program}</td>
                                  <td>{user.student.degree}</td>
                                  <td>{user.student.email}</td>
                                  <td>{user.student.phone_number}</td>

                                  <td>{user.student_prodi.id}</td>
                                  <td>{user.student_prodi.sks_taken}</td>
                                  <td>{user.student_prodi.ipk}</td>
                   
                                  
                                </tr>
                              })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    return (
        <>
            <DosenList/>
            <br/>
            <br></br>
        </>
    )
}







// DashboardListRecommendation
export const DashboardListRecommendation = () => {
    const attr = {
        marginTop : "10%",
        marginLeft: "30px",
        marginRight : "30px"
    }

    const { state, handler } = useContext(GlobalContext)
    const {
        dataRecommendation,
    } = state 

    const {
        handleHistoryBimbingan,
        handlePilih
      } = handler

    const RecommendationList = () => {
        return (
          <div className="" style={attr}>
               <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    NO
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Name
                                </th> 
                                <th scope="col" className="px-6 py-3">
                                    Keahlian
                                </th> 
                                <th scope="col" className="px-6 py-3">
                                    Minat
                                </th> 
                                <th scope="col" className="px-6 py-3">
                                    Sisa Kuota
                                </th> 
                                <th scope="col" className="px-6 py-3">
                                    Relevances
                                </th> 
                                <th scope="col" className="px-6 py-3">
                                    Actions
                                </th> 
                            </tr>
                        </thead>

                        <tbody>
                            {
                              dataRecommendation?.map((user, idx) => {
                                return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={idx}>
                                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{idx+1}</td>
                                  <td>{user.id}</td>
                                  <td>{user.lecturer_name}</td>
                                  <td>{user.keahlian}</td>
                                  <td>{user.minat}</td>
                                  <td>{user.sisa_kuota}</td>
                                  <td>{user.relevances.toFixed(2)}%</td>
                                  <td className="flex space-x-4">
                                  {
                                      <button 
                                      value={user.lecturer_id} 
                                        onClick={handleHistoryBimbingan}
                                      className="bg-green-300 
                                        hover:bg-green-700 text-white font-bold py-2 px-4 border border-green-700 rounded mt-2">
                                      History Bimbingan
                                      </button>
                                    }

                                    {
                                      <button 
                                        onClick={handlePilih} 
                                        value={user.lecturer_id} 
                                        className="bg-blue-300 hover:bg-blue-700 
                                        text-white font-bold py-2 px-4 border border-blue-700 rounded mt-2">
                                        Pilih
                                      </button>
                                    }
                                  </td>
                                </tr>
                              })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    return (
        <>
            <RecommendationList/>
            <br/>
            <br></br>
        </>
    )
}
// ITB
    // const lat = -6.890431992479764;
    // const lon = 107.61100193643512;

// current loc
    // const lat = -1.645981300921818;
    // const lon = 103.584137051162;

let lat, lon 
let distance, volumeVal, status
export const MapAPI = () => {
    const { state, handler } = useContext(GlobalContext)
    const {
        gps,
        fetchStatusGPS,
        volume,
    } = state 

    const {
        handleGPSData,
        handleVolumeData,
    } = handler

    useEffect( () => {
        if (fetchStatusGPS == true ){
            handleGPSData()
            handleVolumeData()
        }
    }, [])  

    lat = Number(gps?.latitude)
    lon = Number(gps?.longitude)

    checkNull(lat)

    // Update the src attribute with multiple markers
    useEffect( () => {
        const iframeData = document.getElementById("iframeId");
        iframeData.src = `https://maps.google.com/maps?q=${lat},${lon}&hl=es;&output=embed`;
    }, [])  
       
    const attr = {
        marginTop : "3%",
        marginLeft: "30px",
        marginRight : "70px"
    }

    distance = volume?.distance
    volumeVal = volume?.volume
    status = volume?.status

    checkNullDistance(distance)

    // publish notification
    if (status == "Penuh") {
        postNotification().then((res) => {
            alert("Successfully send notification")
        })
        .catch((error) => {
            alert(error.code + error)
        })
    }
    

    return (
        <div 
        // className="smart-waste"
         style={attr}>
            <h1><b>Smart Waste Management - Prototype</b></h1>
            <h3>Muhamad Alfarisy - 23223056</h3>
            <br></br>
            <p><b>API Google Map IoT - Integration</b></p>
            <br></br>
            <p>Latitude : {lat}</p>
            <p>Longitude : {lon}</p>
            <br></br>
            <h2><b>Volume Tempat sampah</b></h2>
            <p>Distance : {distance}</p>
            <p>Volume : {volumeVal}</p>
            <p>Status : {status}</p>
            <br></br>
            <iframe id="iframeId" height="500px" width="100%"></iframe>
            <Footer/>
        </div>
    );
}

function checkNullDistance(val) {
    if (isNaN(val)) {
        distance = "5"
        volumeVal = "95"
        status = "Penuh"
    }
}

function checkNull(val) {
    if (isNaN(val)) {
        lat = -1.645981300921818;
        lon = 103.584137051162;
    }
}


// DashboardListHistoryBimbingan
export const DashboardListHistoryBimbingan = () => {
    const attr = {
        marginTop : "5%",
        marginLeft: "30px",
        marginRight : "30px"
    }

    const {id} = useParams()
    const { state, handler } = useContext(GlobalContext)
    const {
        dataBimbinganHistory,
        // fetchStatusLecturerProdi
    } = state 

    const {
        handleFetchLecturerProdi,
    } = handler

    const DosenList = () => {
        return (
          <div className="" style={attr}>
                <h1><b>History bimbingan</b></h1>
                <br></br>
               <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    NO
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th> 
                                <th scope="col" className="px-6 py-3">
                                    Topik
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Student Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Student ID
                                </th>
                                {/* <th scope="col" className="px-6 py-3">
                                    Phone Number
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    ID Prodi
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Kuota
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Penelitian 
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Minat
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Keahlian
                                </th> */}
                            </tr>
                        </thead>

                        <tbody>
                            {
                              dataBimbinganHistory?.map((user, idx) => {
                                return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={idx}>
                                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{idx+1}</td>
                                  <td>{user.id}</td>
                                  <td>{user.status}</td>
                                  <td>{user.topik}</td>
                                  <td>{user.name}</td>
                                  <td>{user.student_id}</td>
                                  
                                </tr>
                              })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    return (
        <>
            <DosenList/>
            <br/>
            <br></br>
        </>
    )
}



// DashboardDosbing
export const DashboardDosbing = () => {
    const attr = {
        marginTop : "5%",
        marginLeft: "30px",
        marginRight : "30px"
    }

    const {id} = useParams()
    const { state, handler } = useContext(GlobalContext)
    const {
        dataLecturer,
    } = state 


    let dataMap = []
    dataMap.push(dataLecturer)
    
    const DosenList = () => {
        return (
          <div className="" style={attr}>
                <h1><b>Calon Dosen Pembimbing Tugas Akhir</b></h1>
                <br></br>
               <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    NO
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Name
                                </th> 
                                <th scope="col" className="px-6 py-3">
                                    Keahlian
                                </th> 
                                <th scope="col" className="px-6 py-3">
                                    Minat
                                </th> 
                                <th scope="col" className="px-6 py-3">
                                    Sisa Kuota
                                </th> 
                            </tr>
                        </thead>

                        <tbody>
                            {
                              dataMap?.map((user, idx) => {
                                return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={idx}>
                                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{idx+1}</td>
                                  <td>{user.lecturer.id}</td>
                                  <td>{user?.lecturer.first_name+" "+user.lecturer.middle_name+" "+user.lecturer.last_name} </td>
                                  <td>{user?.lecturer_prodi.keahlian}</td>
                                  <td>{user?.lecturer_prodi.minat_lecturer}</td>
                                  <td>{user?.lecturer_prodi.kuota}</td>
                                  
                                </tr>
                              })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    return (
        <>
            <DosenList/>
            <br/>
            <br></br>
        </>
    )
}

// ListStatusPengajuan
export const ListStatusPengajuan = () => {
    const attr = {
        marginTop : "5%",
        marginLeft: "30px",
        marginRight : "30px"
    }

    const { state, handler } = useContext(GlobalContext)
    const {
        dataBiddingan,
        fetchStatusBiddingan
    } = state 

    const {
        handleFetchBiddingan,
    } = handler

    useEffect( () => {
        if (fetchStatusBiddingan === true) {
            handleFetchBiddingan()
        }
    }, [handleFetchBiddingan, fetchStatusBiddingan])  

    const DosenList = () => {
        return (
          <div className="" style={attr}>
                <h1><b>STATUS PENGAJUAN</b></h1>
                <br></br>
               <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    NO
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Lecturer ID
                                </th> 
                                <th scope="col" className="px-6 py-3">
                                    TItle
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Created At
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Updated At
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                              dataBiddingan?.map((user, idx) => {
                                return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={idx}>
                                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{idx+1}</td>
                                  <td>{user.id}</td>
                                  <td>{user.lecturer_prodiid}</td>
                                  <td>{user.title}</td>
                                  <td>{user.status}</td>
                                  <td>{user.created_at}</td>
                                  <td>{user.updated_at}</td>
                                  
                                </tr>
                              })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    return (
        <>
            <DosenList/>
            <br/>
            <br></br>
        </>
    )
}


// ListDaftarPengajuan
export const ListDaftarPengajuan = () => {
    const attr = {
        marginTop : "5%",
        marginLeft: "30px",
        marginRight : "30px"
    }

    const { state, handler } = useContext(GlobalContext)
    const {
        dataBiddinganLecturer,
        fetchStatusBiddinganLecturer
    } = state 

    const {
        handleFetchBiddinganLecturer,
        handleReject,
        handleApprove
    } = handler

    useEffect( () => {
        if (fetchStatusBiddinganLecturer === true) {
            handleFetchBiddinganLecturer()
        }
    }, [handleFetchBiddinganLecturer, fetchStatusBiddinganLecturer])  

    const DosenList = () => {
        return (
          <div className="" style={attr}>
                <h1><b>DAFTAR PENGAJUAN</b></h1>
                <br></br>
               <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    NO
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Nama Mahasiswa/NIM
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Topik Tugas Akhir
                                </th> 
                                <th scope="col" className="px-6 py-3">
                                    Status Persetujuan
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Aksi
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                              dataBiddinganLecturer?.map((user, idx) => {
                                return <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={idx}>
                                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{idx+1}</td>
                                  <td>{user.student_data}</td>
                                  <td>{user.title}</td>
                                  <td>{user.status}</td>
                                <td className="flex space-x-4">
                                  {
                                     user.status == "PROPOSED"  &&  <button 
                                     value={user.id} 
                                      onClick={handleApprove}
                                      className="bg-blue-300 
                                        hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded mt-2">
                                      Approve
                                      </button>
                                    }

                                    {
                                       user.status == "PROPOSED"  &&     <button 
                                                onClick={handleReject} 
                                                value={user.id} 
                                                className="bg-red-300 hover:bg-red-700 
                                                text-white font-bold py-2 px-4 border border-red-700 rounded mt-2">
                                                Reject
                                        </button>
                                    }

                                    {
                                        user.status != "PROPOSED" && <div>DONE</div>
                                    }
                                  </td>
                                </tr>
                              })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    return (
        <>
            <DosenList/>
            <br/>
            <br></br>
        </>
    )
}
