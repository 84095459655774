import axios from "axios";
import Cookies from "js-cookie"

const baseUrl = `https://dev-example.sanbercloud.com/api`
const baseUrlSM = `https://backend.farisy.my.id`
const baseUrlLocal = `http://localhost:8000`

// authRegister - enrollment
export const authRegister = (payload) => {
    try {
        return axios.post(`${baseUrl}/register`, payload)
    } catch (error) {
        console.error(error)
        return error
    }
}

// authLogin - login
export const authLogin = async (payload) => {
    try {
        return await axios.post(`${baseUrlSM}/login`, payload)
    } catch (error) {
        return error.response
    }
}

// authChangePassword - change password
export const authChangePassword = (payload) => {
    try {
        return axios.post(`${baseUrl}/change-password`,  
        payload,
        {headers:{"Authorization" : "Bearer "+ Cookies.get('token')}})
    } catch (error) {
        console.error(error)
    }
}