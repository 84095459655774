import { createContext, useState } from "react";
import {getJobsData, getJobDetail, deleteJob, createJob, editJobDetail} from '../Api/apiJob'
import {authLogin, authRegister, authChangePassword} from '../Api/apiAuth'
import Cookies from "js-cookie"
import { useNavigate } from 'react-router-dom'

import {getStudentsData, getStudentsDataByID, getStudentsDataProdi, getStatusPengajuan, getStatusPengajuanLecturer,editStatusBidding} from '../Api/apiStudent'
import {getLecturerData, getLecturerDataProdi, getLecturerDataByID,getLecturerDataProdiByID,getHistoryBimbingan, createBidding} from '../Api/apiLecturer'
import {getRecommendations} from '../Api/apiRecommendation'
import {getsGPSData, getsVolumeData} from '../Api/iot'
import {getSummaryByUserID} from '../Api/apiShip'
import {addTxPool, getTxPool, getBchain,getBchainMine} from '../Api/apiWallet'


const maxItem = 10;

export const GlobalContext = createContext()

export const GlobalProvider = (props)=>{
    let navigate = useNavigate()

    // state
    const [jobs, setJobs] = useState([])
    const [fetchStatus, setFetchStatus] = useState(true)
    const [dataForm, setInput] = useState(
        {
            id: 0,
            title : "",
            job_description : "",
            job_qualification : "",
            job_type : "",
            job_tenure: "",
            job_status : 0,
            company_name : "",
            company_image_url : "",
            company_city: "",
            salary_min : 0,
            salary_max: 0,
       }
    )

    // history bimbingan
    const [dataBimbinganHistory, setdataBimbinganHistory] = useState([])

    // student 
    const [dataStudent, setDataStudent] = useState([])
    const [fetchStatusStudent, setFetchStatusStudent] = useState(true)

    const [dataLecturer, setDataLecturer] = useState([])
    const [fetchStatusLecturer, setFetchStatusLecturer] = useState(true)

    // lecturerProdi
    const [dataLecturerProdi, setDataLecturerProdi] = useState([])
    const [fetchStatusLecturerProdi, setFetchStatusLecturerProdi] = useState(true)


    // txPool 
    const [dataTxPool, setDataTxPool] = useState([])
    const [fetchStatusTxPool, setFetchStatusTxPool] = useState(true)

    // chain
    const [dataChain, setDataChain] = useState([])
    const [fetchStatusChain, setFetchStatusChain] = useState(true)

    // ship summary
     const [dataShip, setDataShip] = useState([])
     const [fetchStatusShip, setFetchStatusShip] = useState(true)
 

    // student prodi
    const [dataStudentProdi, setDataStudentProdi] = useState([])
    const [fetchStatusStudentProdi, setFetchStatusStudentProdi] = useState(true)
    
    // search
    const [inputSearch, setInputSearch] = useState({
        title : ""
    })

    // biddingan
    const [fetchStatusBiddingan, setFetchStatusBiddingan] = useState(true)
    const [dataBiddingan, setDataBiddingan] = useState([])

    // bidding lecturer
    const [fetchStatusBiddinganLecturer, setFetchStatusBiddinganLecturer] = useState(true)
    const [dataBiddinganLecturer, setDataBiddinganLecturer] = useState([])


    // gps data 
    const [gps, setGps] = useState()
    const [volume, setVolume] = useState({})
    const [fetchStatusGPS, setFetchStatusGPS] = useState(true)
    
    const handleChangeSearch = (event) => {
        let value = event.target.value
        let name = event.target.name
        setInputSearch({...inputSearch, [name] : value})
    }

    const handleSearch = (event) => {
        event.preventDefault()
        if (inputSearch.title.length > 0 ) {
            getJobsData().then((result)=> {
                let arrItem = []
                for (let index = 0; index < result.length; index++) {
                    const element = result[index]
                    if (element.title.toLowerCase() === inputSearch.title.toLowerCase()) {
                        arrItem.push(element)
                    }
                }
                setJobs(arrItem)
            })
            setFetchStatus(false)  
            return  
        }
        handleFetch()
    }

    const handleHitung = (event) => {
        navigate('/verify-transaction')
    }


    const handleVerify = (event) => {
        event.preventDefault()
        getBchainMine().then((result)=> {
            alert("Successfully verify data")
            navigate('/input-transaction')
            return
        })
    }
    // filter by categories 
    const handleCategoryStatus = (event) => {
        event.preventDefault()
        let value = event.target.value
        getJobsData().then((result)=> {
            // if all
            if (value === 2) {
                setJobs(result)
                return
            }

            let arrItem = []
            for (let index = 0; index < result.length; index++) {
                const element = result[index]
                if (element.job_status === value) {
                    arrItem.push(element)
                }
            }
            setJobs(arrItem)
        })
        setFetchStatus(false)  
    }

     // gets gps data
     const handleGPSData = () => {
        getsGPSData().then((result)=> {
            if (result.status == 200 ) {
                setGps(result.data)
            }
        })
        setFetchStatusGPS(false)
    }

    // gets gps data
    const handleVolumeData = () => {
        getsVolumeData().then((result)=> {
            if (result.status == 200 ) {
                setVolume(result.data)
            }
        })
    }

    let cities = {
        1 : "Jakarta",
        2 : "Palembang",
        3 : "California",
        4 : "Washington",
        10 : "All"
    }

    const handleCategoryCity = (event) => {
        event.preventDefault()
        let value = event.target.value
        getJobsData().then((result)=> {
            if (cities[value].toLowerCase() === "all") {
                setJobs(result)
                return
            }

            let arrItem = []
            for (let index = 0; index < result.length; index++) {
                const element = result[index]
                if (element.company_city.toLowerCase() === cities[value].toLowerCase()) {
                    arrItem.push(element)
                }
            }
            setJobs(arrItem)
        })
        setFetchStatus(false)  
    }

    let minSalary = {
        0 : 1000000,
        1 : 4000000,
        2 : 7000000,
        3 : "All",
    }

    const handleCategoryMinsalary = (event) => {
        event.preventDefault()
        let value = event.target.value
        getJobsData().then((result)=> {
            if (minSalary[value] === "All") {
                setJobs(result)
                return
            }

            let arrItem = []
            for (let index = 0; index < result.length; index++) {
                const element = result[index]
                if (element.salary_min >= minSalary[value]) {
                    arrItem.push(element)
                }
            }
            setJobs(arrItem)
        })
        setFetchStatus(false)  
    }
    

    // login
    const [inputLogin, setInputLogin] = useState({
        email : "",
        password : ""
    })

    const [inputLoginProdi, setInputLoginProdi] = useState({
        username : "",
        password : ""
    })

    const [inputRecommendation, setInputRecommendation] = useState({
        minat : "",
    })

    const [dataRecommendation, setDataRecommendation] = useState([])


    const [inputTransaction, setInputTransaction] = useState({
      
            weight : 0 ,
            fish_type : "",
            user_id : 0,
            ship_code : ""
        
    })

    const handleChangeSimpan= (event) => {
        let value = event.target.value
        let name = event.target.name
        setInputTransaction({...inputTransaction, [name] : value})
    }

    const handleSimpan = (event) => {
        event.preventDefault()
  
        let payload = inputTransaction
        payload.ship_code = Cookies.get("ship_code")
        payload.user_id = parseInt(payload.user_id)
        payload.weight = parseInt(payload.weight)
        addTxPool(payload).then((res) => {
            if (res.status == 400) {
                alert("bad input")
            }  else {
                alert("Successfully add transaction data to pool")
            }
        })
   
        navigate('/input-transaction')
    }

    const [inputBidding, setInputBidding] = useState({
        title : "",
        lecturer_prodiid : 0,
        student_prodiid : 0

    })

    const handleAddBidding = (ev) => {
        ev.preventDefault()
        let studentID = Cookies.get('id')
        getStudentsDataProdi().then((res) => {
            res = res.data.data
            for (let index = 0; index < res.length; index++) {
                const element = res[index];
                if (studentID === element.student.id ) {
                    studentID = element.student_prodi.id
                }
                
            }

            inputBidding.lecturer_prodiid = Number(dataLecturer.lecturer_prodi.id)
            inputBidding.student_prodiid = Number(studentID)

            createBidding(inputBidding).then((res) => {
                if (res.status == 400) {
                    alert(res.data.message)
                } else {
                    alert("Berhasil mengajukan bidding topik")
                    navigate('/')
                }
        })

            setInputBidding (
                {
                    topik : "",
                    lecturer_prodiid : 0,
                    student_prodiid : 0
                }
            )
        }) 
        
        
    }

    // recommendation
    const handleChangeMinat= (event) => {
        let value = event.target.value
        let name = event.target.name
        setInputRecommendation({...inputRecommendation, [name] : value})
    }

    const handleRecommendation = (event) => {
        event.preventDefault()
        getRecommendations(inputRecommendation).then((res) => {
            let mapRec = {};
            for (let index = 0; index < res.length; index++) {
                const element = res[index];
                mapRec[element.id] = {
                    id : element.id,
                    lecturer_name : element.lecturer_name,
                    relevances : element.relevances
                }
            }


            getLecturerDataProdi().then((res2) =>{
                let data = []
                for (let index = 0; index < res2.length; index++) {
                    const element = res2[index];
                    if (mapRec[element.lecturer.id] ) {
                        mapRec[element.lecturer.id].sisa_kuota = element.lecturer_prodi.kuota 
                        mapRec[element.lecturer.id].keahlian = element.lecturer_prodi.keahlian 
                        mapRec[element.lecturer.id].minat = element.lecturer_prodi.minat_lecturer 
                        mapRec[element.lecturer.id].lecturer_id = element.lecturer_prodi.id 
                    }
                }

                for (const key in mapRec) {
                    if (Object.hasOwnProperty.call(mapRec, key)) {
                        const element = mapRec[key];
                        data.push(element)
                    }
                }

                setDataRecommendation(data)
                navigate('/recommendation-result')
            })
        })
    }


    const handleChangeLogin = (event) => {
        let value = event.target.value
        let name = event.target.name
        setInputLogin({...inputLogin, [name] : value})
    }

    const handleLogin = (event) => {
        event.preventDefault()
        authLogin(inputLogin).then((res) => {
            let {token} = res.data
            Cookies.set('token', token, {expires : 1})
            alert("Successfully logged in")
            navigate('/')
        })
        .catch((error) => {
            alert(error.code + ": Email or password might be wrong!")
        })
    }

    const handleChangeLoginProdi = (event) => {
        let value = event.target.value
        let name = event.target.name
        setInputLoginProdi({...inputLoginProdi, [name] : value})
    }

    const handleLoginProdi = (event) => {
        event.preventDefault()
      
        authLogin(inputLoginProdi).then((res) => {
            if (res.status == 400) {
                alert("wrong username or password")
                navigate('/login')
            }  else {
                let resp = res.data.data
                Cookies.set('id', resp.id)
                Cookies.set('name',resp.username )
                Cookies.set('role', resp.role )
                Cookies.set('ship_code', resp.ship_code )
                Cookies.set('zone', resp.zone )
                alert("Successfully logged in")
                navigate('/')
                return
            }
        })

    }

    // register
    const [inputRegister, setInputRegister] = useState({
        name : "",
        image_url : "",
        email : "",
        password : ""
    })

    const handleChangeRegister = (event) => {
        let value = event.target.value
        let name = event.target.name
        setInputRegister({...inputRegister, [name] : value})
    }

    const handleRegister = (event) => {
        event.preventDefault()
        authRegister(inputRegister).then((res) => {
            alert("Successfully registered")
            navigate('/login')
        })
        .catch((error) => {
            alert(error.response?.data)
        })
    }

    // password
    const [inputPassword, setInputPassword] = useState({
        current_password : "",
        new_password : "",
        new_confirm_password : ""
    })

    const handleChangePassword = (event) => {
        let value = event.target.value
        let name = event.target.name
        setInputPassword({...inputPassword, [name] : value})
    }

    const handlePassword = (event) => {
        event.preventDefault()
        if (Cookies.get('token') === undefined) {
            alert("Please login first")
            navigate('/login')
            return 
        }
        authChangePassword(inputPassword).then((res) => {
            if (res.status === 200) {
                alert("Successfully change password")
                Cookies.remove('token')
                navigate('/login')
            }
        })
        .catch((error) => {
            alert(error.code + ": Data password lama atau baru tidak tepat")
        })
    }

    // indikator
    const [currentId, setCurrentId] = useState(-1)

    let state = {
        jobs, setJobs,
        fetchStatus, setFetchStatus,
        currentId, setCurrentId,
        dataForm, setInput,

        inputLogin, setInputLogin,
        inputRegister, setInputRegister,

        inputSearch, setInputSearch,

        inputPassword, setInputPassword,

        // student

        dataStudent, setDataStudent,
        fetchStatusStudent,


        dataLecturer, setDataLecturer,
        fetchStatusLecturer, setFetchStatusLecturer,
        // inputCategoryStatus, setCategoryStatus

        dataLecturerProdi, setDataLecturerProdi,
        fetchStatusLecturerProdi, setFetchStatusLecturerProdi,

        inputLoginProdi, setInputLoginProdi,
        inputTransaction, setInputTransaction,

        dataRecommendation, setDataRecommendation,
        inputRecommendation, setInputRecommendation,

        dataBimbinganHistory, setdataBimbinganHistory,

        inputBidding, setInputBidding,

        dataBiddingan, setDataBiddingan,
        fetchStatusBiddingan, setFetchStatusBiddingan,

        fetchStatusBiddinganLecturer, setFetchStatusBiddinganLecturer,
        dataBiddinganLecturer, setDataBiddinganLecturer,
        
        dataStudentProdi, setDataStudentProdi,
        fetchStatusStudentProdi, setFetchStatusStudentProdi,

        // ship
        dataShip, setDataShip,
        fetchStatusShip, setFetchStatusShip,

        // chain
        dataTxPool, setDataTxPool,
        fetchStatusTxPool, setFetchStatusTxPool,
        dataChain, setDataChain,
        fetchStatusChain, setFetchStatusChain,

        gps, setGps,
        fetchStatusGPS, setFetchStatusGPS,
        volume, setVolume,
    }

    // handleFetchSummaryShip - handles fetch summary
    const handleFetchSummaryShip = () => {
        let id = Cookies.get('id')
        getSummaryByUserID(id).then((result)=> {
            setDataShip(result)
        })
        setFetchStatusShip(false)
    }

    // handleFetchTxPool - 
    const handleFetchTxPool = () => {
        getTxPool().then((result)=> {
            setDataTxPool(result)
        })
        setFetchStatusTxPool(false)
    }

    // handleFetchChain
    const handleFetchChain = () => {
        getBchain().then((result)=> {
            setDataChain(result)
        })
        setFetchStatusChain(false)
    }

    const handleFetchLecturerProdi = () => {
        getLecturerDataProdi().then((result)=> {
            setDataLecturerProdi(result)
        })
        setFetchStatusLecturerProdi(false)
    }

    const handleFetchStudentProdi = () => {
        getStudentsDataProdi().then((result)=> {
            setDataStudentProdi(result.data.data)
        })
        setFetchStatusStudentProdi(false)
    }

    const handleFetchBiddingan = () => {
        // let studentID = Cookies.get('id')
        // getStudentsDataProdi().then((res) => {
        //     res = res.data.data
        //     for (let index = 0; index < res.length; index++) {
        //         const element = res[index];
        //         if (studentID === element.student.id ) {
        //             studentID = element.student_prodi.id
        //         }
        //     }
        //     getStatusPengajuan(studentID).then((res)=>{
        //         setDataBiddingan(res)
        //     })
        //     setFetchStatusBiddingan(false)
        // })
    }

    const handleFetchBiddinganLecturer = () => {
        let lecturerID = Cookies.get('id')

        if (lecturerID == undefined) {
            return 
        }

        if (Cookies.get('role') == 5){
            return
        }

        getLecturerDataProdi().then((res) => {
            for (let index = 0; index < res.length; index++) {
                const element = res[index];
                if (lecturerID === element.lecturer.id ) {
                    lecturerID = element.lecturer_prodi.id
                }
            }

            getStatusPengajuanLecturer(lecturerID).then((res2)=>{
                getStudentsDataProdi().then((res3) => {

                    let mapStd = {}
                    res3 = res3.data.data
                    for (let index = 0; index < res3.length; index++) {
                        const element = res3[index];
                        mapStd[element.student_prodi.id] = element
                    }
                    
                    let data = []
                    for (let index = 0; index < res2.length; index++) {
                        const element = res2[index];
                        if (mapStd[element.student_prodiid]) {
                            data.push(
                                {
                                    "title" : element.title,
                                    "status" : element.status,
                                    "id" : element.id,
                                    "student_data" : mapStd[element.student_prodiid].student.first_name +"/"+ mapStd[element.student_prodiid].student.id 
                                }
                            )
                        }
                        
                    }

                    setDataBiddinganLecturer(data)
                    setFetchStatusBiddinganLecturer(false)
                })

               
            })
           
        })
    }

    const handleApprove = (ev) => {
        let idData = parseInt(ev.target.value)
        let payload = {
            "status" : "APPROVED"
        }
        editStatusBidding(idData,payload).then((res) => {
            if (res.status == 200) {
                setFetchStatusBiddinganLecturer(true)
                alert("Success ubah data bidding mahasiswa")
            }
            else {
                alert("Failed ubah data bidding mahasiswa")
            }
        })
    }

    const handleReject = (ev) => {
        let idData = parseInt(ev.target.value)
        let payload = {
            "status" : "REJECTED"
        }

        editStatusBidding(idData,payload).then((res) => {
            if (res.status == 200) {
                setFetchStatusBiddinganLecturer(true)
                alert("Success ubah data bidding mahasiswa")
            }
            else {
                alert("Failed ubah data bidding mahasiswa")
            }
        })
    }

    // Student data
    const handleFetchLecturer = () => {
        getLecturerData().then((result)=> {
            setDataLecturer(result)
        })
        setFetchStatusLecturer(false)
    }

    // Student data
    const handleFetchStudent = () => {
        // getStudentsData().then((result)=> {
        //     let arrItem = []
        //         for (let index = 0; index < maxItem; index++) {
        //             arrItem.push(result[index])
        //         }
        //     setDataStudent(arrItem)
        // })
        // setFetchStatusStudent(false)
    }

    // pilih dosbing
    const handlePilih = (ev) => {
        let idData = parseInt(ev.target.value)
        getLecturerDataProdiByID(idData).then((result)=> {
            setDataLecturer(result)
            navigate(`/bidding`)
        })
    }


    //  Job data
    const HandelDeleteJob = (ev) => {
        deleteJob(ev).then((res)=>{
            if (res.status === 200) {
                setFetchStatus(true)
            }
        })
      }
    
    const handleFetch = () => {
        getJobsData().then((result)=> {
            setJobs(result)
        })
        setFetchStatus(false)
    }

    const handleJobDetail = (ev) => {
        let idData = parseInt(ev.target.value)
        getJobDetail(idData).then((result)=> {
            setInput(result.data)
            navigate(`/job-vacancy/${idData}`)
        })
    }

    const handleHistoryBimbingan = (ev) => {
        let idData = parseInt(ev.target.value)
        getHistoryBimbingan(idData).then((result)=> {
            let mapRec = {};
            for (let index = 0; index < result.length; index++) {
                const element = result[index];
                mapRec[element.student_prodiid] = {
                    id : element.id,
                    student_id : element.student_prodiid,
                    status : element.status,
                    topik : element.topik
                }
            }

            // mapping nama siswanya
            getStudentsDataProdi().then((res2) => {
                let data = []
                res2 = res2.data.data
                for (let index = 0; index < res2.length; index++) {
                    const element = res2[index];
                    if (mapRec[element.student_prodi.id] ) {
                        mapRec[element.student_prodi.id].name = element.student.first_name + " "+ element.student.middle_name + " " + element.student.last_name 
                    }
                }
                for (const key in mapRec) {
                    if (Object.hasOwnProperty.call(mapRec, key)) {
                        const element = mapRec[key];
                        data.push(element)
                    }
                }

                setdataBimbinganHistory(data)
                navigate(`/history-bimbingan/${idData}`)
            })
            
        })
    }

    const handleEdit = (ev) => {
        let idData = parseInt(ev.target.value)
        navigate(`/dashboard/list-job-vacancy/edit/${idData}`)
        getJobDetail(idData).then((res) =>{
            setInput(res.data)
        })
        setCurrentId(idData)
    }

    const handleInput =  (ev) => {
        setInput((prev) => {
            let helper = {...prev}
            helper[ev.target.name] = ev.target.value
            return helper;
        })
    }

    const handleSubmit = (ev) => {
        ev.preventDefault()
  
        if (currentId === -1) {
            createJob(dataForm).then((res) => {
                if (res.status === 201) {
                    alert("Berhasil menambahkan data")
                    setFetchStatus(true)
                }
            })
        } 
        else {
            editJobDetail(currentId, 
                dataForm
            ).then((res) => {
                if (res.status === 200) {
                    alert("Berhasil mengedit data")
                    setFetchStatus(true)
                    navigate('/dashboard/list-job-vacancy')
                }
            })
        }
        
        // reset
        setCurrentId(-1)
        setInput(
            {
                id: 0,
                title : "",
                job_description : "",
                job_qualification : "",
                job_type : "",
                job_tenure: "",
                job_status : 0,
                company_name : "",
                company_image_url : "",
                company_city: "",
                salary_min : 0,
                salary_max: 0,
            }
        )
    }

    // HANDLER
    let handler = {
        handleEdit,
        handleInput,
        handleSubmit,
        HandelDeleteJob,
        handleFetch,

        handleLogin,
        handleChangeLogin,

        handleChangeRegister,
        handleRegister,

        handleSearch,
        handleChangeSearch,
        handleJobDetail,

        handleChangePassword,
        handlePassword,

        handleCategoryCity,
        handleCategoryStatus,
        handleCategoryMinsalary,

        // student
        handleFetchStudent,
        handleFetchLecturer,

        // prodi
        handleFetchLecturerProdi,

        // summary - ship
        handleFetchSummaryShip,

        // bchain
        handleFetchTxPool,
        handleFetchChain,

        handleHitung,
        handleVerify,
        handleSimpan,
        handleLoginProdi,
        handleChangeLoginProdi,
        handleChangeSimpan,

        handleRecommendation,
        handleChangeMinat,

        handleHistoryBimbingan,
        handlePilih,
        // handleChangeBidding,
        handleAddBidding,
        handleFetchBiddingan,
        handleFetchBiddinganLecturer,

        handleApprove,
        handleReject,

        handleFetchStudentProdi,

        handleGPSData,
        handleVolumeData,
    }

    return (
        <GlobalContext.Provider value = {{
                state,
                handler
            }}
        >
            {props.children}
        </GlobalContext.Provider>
    )
}