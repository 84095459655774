import React from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie"
import { useNavigate } from 'react-router-dom'
import '../styles.css';
import { GlobalContext } from '../Context/context'
import { useContext ,useEffect} from 'react'

const Navbar = () => {
    let navigate = useNavigate()
    return(
    <>
        <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900 navbar_prodi">
            <div className="container flex flex-wrap justify-between items-center mx-auto">
                
                <a href="/" className="flex items-center">
                    <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Job Listing</span>
                </a>

                <button data-collapse-toggle="navbar-default" type="button"
                    className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                    aria-controls="navbar-default" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                    </svg>
                </button>

                <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                    <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
                        <li>
                            <Link to="/"className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent
                            md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">
                                Home</Link>
                        </li>

                        {/* login & logout */}
                        {
                            !Cookies.get('token') && <li><Link to={'/login'}
                            className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent
                            md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                            >Login</Link></li>
                        }

                        {Cookies.get('token') && <li><span onClick={() => {
                            Cookies.remove('token')
                            navigate('/login')
                        }}

                        className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent 
                            md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 
                            dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                        >Logout</span></li>}

                        {/* Dashboard */}
                        {Cookies.get('token') && <li><span 
                            onClick={() => {
                                navigate('/dashboard')
                            }}
                        className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent 
                            md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 
                            dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                        >Dashboard</span></li>}


                    </ul>
                </div>


            </div>
        </nav>
    </>
    )
    
}

export default Navbar


export const NavbarProdi = (props) => {
    let navigate = useNavigate()
    // const { state, handler } = useContext(GlobalContext)
    // const {
    //     dataBiddinganLecturer,
    //     fetchStatusBiddinganLecturer
    // } = state 

    // const {
    //     handleFetchBiddinganLecturer,

    // } = handler
    // useEffect( () => {
    //     if (fetchStatusBiddinganLecturer === true) {
    //         handleFetchBiddinganLecturer()
    //     }
    // }, [handleFetchBiddinganLecturer, fetchStatusBiddinganLecturer])  


    // let dataNew = []
    // let lenData = 0

    // if (dataBiddinganLecturer != null) {
    //     for (let index = 0; index < dataBiddinganLecturer.length; index++) {
    //         const element = dataBiddinganLecturer[index];
    //         if (element.status == "PROPOSED"){
    //             dataNew.push(element)
    //         }
    //     }
    // }
   

    // if (dataNew != null ){
    //     lenData = dataNew.length
    // }
    
    return(
    <>
        <nav className="border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900" 
        // style={props.style}
        >
            <div className="container flex flex-wrap justify-between items-center mx-auto">
                <a href="/" className="flex items-center">
                    <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white"></span>
                </a>

                <button data-collapse-toggle="navbar-default" type="button"
                    className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                    aria-controls="navbar-default" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                    </svg>
                </button>

                <div className="hidden w-full md:block md:w-auto" id="navbar-default">
                    <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
                        <div>
                            <a href="/" className="inline-block text-sm px-4 py-2 leading-none border rounded 
                            text-green border-green hover:border-transparent hover:text-teal-500 hover:bg-green mt-4 lg:mt-0">Home</a>
                        </div>

                        {/* ship summart  */}
                        {
                            Cookies.get('id') && Cookies.get('role') != "admin" &&
                            <div>
                                <a href="/ship-summary" className="inline-block text-sm px-4 py-2 leading-none border rounded 
                                text-green border-green hover:border-transparent hover:text-teal-500 hover:bg-green mt-4 lg:mt-0">Summary</a>
                            </div>

                        }

                        {/* Input transaction */}
                        {
                           Cookies.get('id') && Cookies.get('role') == "admin"  &&
                            <div>
                            <a href="/input-transaction" className="inline-block text-sm px-4 py-2 leading-none border rounded 
                            text-green border-green hover:border-transparent hover:text-teal-500 hover:bg-green mt-4 lg:mt-0">Input Transaction</a>
                        </div>
                        }

                        {/* List transaction */}
                        {
                            Cookies.get('id')  && 
                            <div>
                            <a href="/list-transaction" className="inline-block text-sm px-4 py-2 leading-none border rounded 
                            text-green border-green hover:border-transparent hover:text-teal-500 hover:bg-green mt-4 lg:mt-0">List Transactions</a>
                        </div>
                        }

                        {/* List verified transaction */}
                        {
                            Cookies.get('id') && Cookies.get('role') == "admin" &&
                            <div>
                            <a href="/verified-transactions" className="inline-block text-sm px-4 py-2 leading-none border rounded 
                            text-green border-green hover:border-transparent hover:text-teal-500 hover:bg-green mt-4 lg:mt-0">List Verified Transactions</a>
                        </div>
                        }


                        {/* TODO : */}

                        {/* Pengajuan Navbar */}
                        {
                            Cookies.get('id') && Cookies.get('role') == 5  && <div>
                            <a href="/input-transaction" className="inline-block text-sm px-4 py-2 leading-none border rounded 
                            text-green border-green hover:border-transparent hover:text-teal-500 hover:bg-green mt-4 lg:mt-0">Pengajuan Baru</a>
                        </div>
                        }
                        
                        {/* Status Navbar */}
                        {
                            Cookies.get('id') && Cookies.get('role') == 5  &&  <div>
                            <a href="/status-pengajuan" className="inline-block text-sm px-4 py-2 leading-none border rounded 
                            text-green border-green hover:border-transparent hover:text-teal-500 hover:bg-green mt-4 lg:mt-0">Status Pengajuan
                            {/* <span className="top-0 right-0 rounded-full text-xs text-white px-2 py-1 translate-x-1/2 bg-red-500">
                                {lenData}
                            </span> */}
                            
                            </a>
                        </div>
                        }

                        {/* Daftar siswa */}
                        {
                            Cookies.get('id') && Cookies.get('role') == 3  &&  <div>
                            <a href="/student-list" className="inline-block text-sm px-4 py-2 leading-none border rounded 
                            text-green border-green hover:border-transparent hover:text-teal-500 hover:bg-green mt-4 lg:mt-0">Student 
                            </a>
                        </div>
                        }

                        {/* Daftar pengajuan */}
                        {/* {
                            Cookies.get('id') && Cookies.get('role') == 3  &&  <div>
                            <a href="/daftar-pengajuan" className="inline-block text-sm px-4 py-2 leading-none border rounded 
                            text-green border-green hover:border-transparent hover:text-teal-500 hover:bg-green mt-4 lg:mt-0">Daftar Pengajuan 
                            <span className="top-0 right-0 rounded-full text-xs text-white px-2 py-1 translate-x-1/2 bg-red-500">
                                {lenData}
                            </span>
                            </a>
                        </div>
                        } */}

                        {/* Login Navbar */}
                        {
                            !Cookies.get('id') && <div>
                            <a href="/login" className="inline-block text-sm px-4 py-2 leading-none border rounded 
                            text-green border-green hover:border-transparent hover:text-teal-500 hover:bg-green mt-4 lg:mt-0">Login</a>
                        </div>
                        }


                        {/* Logout navbar */}
                        {
                            Cookies.get('id') && <div
                            onClick={() => {
                                Cookies.remove('id')
                                Cookies.remove('name')
                                Cookies.remove('role')
                                Cookies.remove('ship_code')
                                Cookies.remove('zone')
                                alert("Successfully logout, thankyou!")
                                navigate('/login')
                            }}
                            >
                            <a href="/login" className="inline-block text-sm px-4 py-2 leading-none border rounded 
                                text-green border-green hover:border-transparent hover:text-teal-500 hover:bg-green mt-4 lg:mt-0">
                                    Logout</a>
                            </div>
                        }

                        {/* Name Navbar */}
                        {
                            Cookies.get('id') && <div>
                            <p href="/"  className="inline-block text-sm px-4 py-2 leading-none border rounded 
                            text-green border-green hover:border-transparent hover:text-teal-500 hover:bg-green mt-4 lg:mt-0">Welcome {Cookies.get('name')} as {Cookies.get('role')} !
                            
                            
                            </p>
                        </div>
                        }
                    </ul>
                </div>


            </div>
        </nav>
    </>
    )
    
}