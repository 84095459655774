import axios from "axios";

const baseUrl = `https://api.smartcampus.my.id/public`
const baseUrlProdi = `https://backend.farisy.my.id`

// getStudentsData - get student list
export const getStudentsData = async () => {
    try {
        return await axios.get(`${baseUrl}/student`)

    } catch (error) {
        console.error(error)
        return error.response
    }
}

// getStudentsData - get student list
export const getStudentsDataByID = async (id) => {
    try {
        const apps = await axios.get(`${baseUrl}/student/${id}`)
        return apps

    } catch (error) {
        return error.response
    }
}

// getStudentsData - get student list
export const getStudentsDataProdi= async () => {
    try {
        const apps = await axios.get(`${baseUrlProdi}/student`)
        return apps

    } catch (error) {
        return error.response
    }
}

export const getStatusPengajuan= async (id) => {
    try {
        const apps = await axios.get(`${baseUrlProdi}/bidding/${id}`, { params: { is_student:true }})
        return apps.data.data

    } catch (error) {
        return error.response
    }
}

export const getStatusPengajuanLecturer= async (id) => {
    try {
        const apps = await axios.get(`${baseUrlProdi}/bidding/${id}`, { params: { is_lecturer:true }})
        return apps.data.data

    } catch (error) {
        return error.response
    }
}

// editStatusBidding - edit job
export const editStatusBidding = async (id, payload) => {
    try {
        return await axios.put(`${baseUrlProdi}/bidding/${id}`, payload,)
    } catch (error) {
        return error.response
    }
}