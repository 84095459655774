import axios from "axios";

const baseUrlProdi = `https://backend.farisy.my.id`

// getRecommendations - gets recommendation
export const getRecommendations = async (queryParam) => {
    try {
        let res = null
        res = await axios.get(`${baseUrlProdi}/recommend-supervisor`, { params: { minat:queryParam.minat } })
        return res.data.data

    } catch (error) {
        return error.response
    }
}