import React from "react"
import Navbar from "../Navbar/navbar"
import {NavbarProdi} from "../Navbar/navbar"
import {Dashboard} from "../Pages/dashboard"
import {Footer} from "../Footer/footer"
import style from '../styles.css';
import Cookies from "js-cookie"


import { useContext } from 'react'
import { GlobalContext } from '../Context/context'

export const Layout = (props) => {
    return(
        <>
            <Navbar />
            {props.children}
            <Footer/>
        </>
    )
}

export const LayoutDashboard = (props) => {
    return(
        <>
            <Dashboard />
            {props.children}
            <Footer/>
        </>
    )
}

// Layout404 - layout not found
export const Layout404 = (props) => {
    return(
        <>
            {props.children}
            <Footer/>
        </>
    )
}

// fetch endpoint ship summary
export const LayoutShipInfo = (props) => {
    return(
        <>
            <div className="ship-summary">
                <NavbarProdi 
                className={style.navbar_prodi}
                // style={mystel}                
                />
                {props.children}
            </div>
            <Footer/>
        </>
    )
}

// LayoutHomePage - handles landing page
export const LayoutHomePage = (props) => {
    const mystel = {
        background : "transparent"
    }
    let test = Cookies.get('student_id')
    return(
        <>
            <div className="home-page">
            <NavbarProdi 
                className={style.navbar_prodi}
                // style={mystel}                
                />
                {props.children}
            </div>
            <Footer/>
        </>
    )
}

// LayoutLogin - handle layout login
export const LayoutLogin = (props) => {
    const mystel = {
        "background" : "transparent"
    }
    const attrForm = {
        marginTop: "100px",
        marginLeft : "60%"
    }
    return(
        <>
            <div className="login">
                <NavbarProdi 
                // className={style.navbar_prodi}
                style={mystel}                
                />
                <FormLogin
                // className={style.form-prodi}
                style={attrForm}
                />
                {props.children}
            </div>

            <Footer/>
        </>
    )
}

// LayoutInputTransaction - layout input transaksi
export const LayoutInputTransaction= (props) => {
    const mystel = {
        "background" : "transparent"
    }

    const attrForm = {
        marginTop: "11%",
        marginLeft : "6%"
    }

    return(
        <>
            <div className="input-transaction">
                <NavbarProdi 
                    style={mystel}                
                />
                <br></br>
                <br></br>
                <FormInputTransaction
                    style={attrForm} 
                />

               {props.children}
            </div>

            <Footer/>
        </>
    )
}

// LayoutListVerifiedTransaction - layout list verified
export const LayoutListVerifiedTransaction= (props) => {
    const mystel = {
        "background" : "transparent"
    }

    const attrForm = {
        marginTop: "11%",
        marginLeft : "6%"
    }

    return(
        <>
            <div className="input-transaction">
                <NavbarProdi 
                    style={mystel}                
                />
                <br></br>
                <br></br>
                <FormInputTransaction
                    style={attrForm} 
                />

               {props.children}
            </div>

            <Footer/>
        </>
    )
}

// LayoutListTransactions - layout list transaksi
export const LayoutListTransactions= (props) => {
    const mystel = {
        "background" : "transparent"
    }

    const attrForm = {
        marginTop: "11%",
        marginLeft : "6%"
    }

    return(
        <>
            <div className="list-transaction">
                <NavbarProdi 
                    style={mystel}                
                />

               {props.children}
            </div>

            <Footer/>
        </>
    )
}


export const LayoutBidding= (props) => {
    const mystel = {
        "background" : "transparent"
    }
    const attrForm = {
        marginTop: "11%",
        marginLeft : "6%"
    }
    return(
        <>
            <div className="home-pengajuan-baru">
                <NavbarProdi 
                // className={style.navbar_prodi}
                style={mystel}                
                />
                <FormBidding
                 style={attrForm} />
               {props.children}
            </div>

            <Footer/>
        </>
    )
}

export const LayoutRecommendationResult= (props) => {
    const mystel = {
        "background" : "transparent"
    }
    const attrForm = {
        marginTop: "20%",
        marginLeft : "20px"
    }
    return(
        <>
            <div className="home-pengajuan-baru">
                <NavbarProdi 
                // className={style.navbar_prodi}
                style={mystel}                
                />
                {/* <FormRekomendasi
                 style={attrForm} /> */}
               
            </div>

            <Footer/>
        </>
    )
}

export const FormBidding = (props) => {
    let id = Cookies.get('id')
    let name =  Cookies.get('name')
    const attr = {
        marginRight : "30%"
    }
    const { state, handler } = useContext(GlobalContext)
    const {
        inputBidding,
        inputRecommendation,
        dataLecturer
    } = state 

    const {
        handleChangeBidding,
        handleAddBidding,
    } = handler


    return(
        <div className="" style={props.style}>
        <div className="mt-2">
            <form
             onSubmit={handleAddBidding}
            >
                
            {/* nim */}
            <div className="flex flex-col md:flex-row border-b border-gray-200 pb-4 mb-4">
            <div className="w-64 font-bold h-6 mx-2 mt-3 text-gray-800">
                NIM
            </div>
            <div className="flex-1 flex flex-col md:flex-row">
                <div className="w-full flex-1 mx-2">
                <p>{id}</p>
                </div>
            </div>
            </div>

            {/* nama  */}
            <div className="flex flex-col md:flex-row border-b border-gray-200 pb-4 mb-4">
            <div className="w-64 font-bold h-6 mx-2 mt-3 text-gray-800">
                NAMA
            </div>
            <div className="flex-1 flex flex-col md:flex-row">
                <div className="w-full flex-1 mx-2">
                <p>{name}</p>
                </div>
            </div>
            </div>


            {/* username */}
            <div className="flex flex-col md:flex-row border-b border-gray-200 pb-4 mb-4">
            <div className="w-64 font-bold h-6 mx-2 mt-3 text-gray-800">
                MINAT
            </div>
            <div className="flex-1 flex flex-col md:flex-row">
                <div className="w-full flex-1 mx-2" style={attr.style}>
                <div className="my-2 p-1 bg-white flex border border-gray-200 rounded">
                    <input
                    placeholder="SOA, Signal Processingg ...."
                    className="p-1 px-2 appearance-none outline-none w-full text-gray-800 "
                    id="minat" name="minat" type="minat"
                    onChange={handleChangeBidding}
                    value={inputRecommendation.minat}
                    readOnly
                   />{" "}
                </div>
                </div>
            </div>
            </div>

            <div className="flex flex-col md:flex-row border-b border-gray-200 pb-4 mb-4">
            <div className="w-64 font-bold h-6 mx-2 mt-3 text-gray-800">
                TOPIK TUGAS AKHIR
            </div>
            <div className="flex-1 flex flex-col md:flex-row">
                <div className="w-full flex-1 mx-2" style={attr.style}>
                <div className="my-2 p-1 bg-white flex border border-gray-200 rounded">
                    <input
                    placeholder="Masukkan topik penelitian.."
                    className="p-1 px-2 appearance-none outline-none w-full text-gray-800 "
                    id="title" name="title" type="title"
                    onChange={handleChangeBidding} value={inputBidding.title}
                    required
                   />{" "}
                </div>
                </div>
            </div>
            </div>

            <div className="flex flex-col md:flex-row">
            <div className="w-64 mx-2 font-bold h-6 mt-3 text-gray-800" />
            <div className="flex-1 flex flex-col md:flex-row">
                <button type="submit" 

                className="text-sm  mx-2 w-32  focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer 
                    hover:bg-teal-700 hover:text-teal-100 
                    bg-teal-100 
                    text-teal-700 
                    border duration-200 ease-in-out 
                    border-teal-600 transition"
                >
                Submit Pengajuan
                </button>
            </div>
            </div>

            </form>
            
        </div>
        </div>
    )
}

// FormInputTransaction  - forms input transaction
// need to hit api bchain/transaction
export const FormInputTransaction = (props) => {
    let id = Cookies.get('id')
    let name =  Cookies.get('name')
    const attr = {
        marginRight : "30%"
    }
    const { state, handler } = useContext(GlobalContext)
    const {
        inputTransaction
    } = state 

    const {
        handleSimpan,
        handleChangeSimpan
    } = handler


    return(
        <div className="" style={props.style}>
        <div className="mt-2">
            <form
             onSubmit={handleSimpan}
            >
                
            {/* shipID */}
            <div className="flex flex-col md:flex-row border-b border-gray-200 pb-4 mb-4">
                <div className="w-64 font-bold h-6 mx-2 mt-3 text-gray-800">
                    User ID (PIC)
                </div>

                <div className="flex-1 flex flex-col md:flex-row">
                    <div className="w-full flex-1 mx-2" style={attr.style}>
                    <div className="my-2 p-1 bg-white flex border border-gray-200 rounded">
                        <input
                        placeholder="kapalabc"
                        className="p-1 px-2 appearance-none outline-none w-full text-gray-800 "
                        id="user_id" name="user_id" type="user_id"
                        onChange={handleChangeSimpan} value={inputTransaction.user_id}
                        read
                    />{" "}
                    </div>
                    </div>
                </div>
                
                <div className="flex-1 flex flex-col md:flex-row">
                    <div className="w-full flex-1 mx-2">
                    {/* <p>{id}</p> */}
                    </div>
                </div>
            </div>

            {/* fishType  */}
            <div className="flex flex-col md:flex-row border-b border-gray-200 pb-4 mb-4">
                <div className="w-64 font-bold h-6 mx-2 mt-3 text-gray-800">
                    JENIS IKAN
                </div>

                <div className="flex-1 flex flex-col md:flex-row">
                    <div className="w-full flex-1 mx-2" style={attr.style}>
                    <div className="my-2 p-1 bg-white flex border border-gray-200 rounded">
                        <input
                        placeholder="cumi, sotong, belanak, samge, golok-golok, lundu"
                        className="p-1 px-2 appearance-none outline-none w-full text-gray-800 "
                        id="fish_type" name="fish_type" type="fish_type"
                        onChange={handleChangeSimpan} value={inputTransaction.fish_type}
                        read
                    />
                    {" "}
                    </div>
                    {/* <select className='form-select'  value={ inputTransaction.fish_type } required onChange={ handleChangeSimpan }>
                        <option>-Select fish types-</option>
                        <option value="cumi">cumi</option>
                        <option value="sotong">sotong</option>
                        <option value="belanak">belanak</option>
                        <option value="samge">samge</option>
                        <option value="golok-golok">golok-golok</option>
                        <option value="lundu">lundu</option>
                        </select> */}
                    </div>
                </div>
            
                <div className="flex-1 flex flex-col md:flex-row">
                    <div className="w-full flex-1 mx-2">
                    {/* <p>{name}</p> */}
                    </div>
                </div>
            </div>
        


            {/* weight */}
            <div className="flex flex-col md:flex-row border-b border-gray-200 pb-4 mb-4">
                <div className="w-64 font-bold h-6 mx-2 mt-3 text-gray-800">
                    BERAT TANGKAPAN (KG)
                </div>

                <div className="flex-1 flex flex-col md:flex-row">
                    <div className="w-full flex-1 mx-2" style={attr.style}>
                    <div className="my-2 p-1 bg-white flex border border-gray-200 rounded">
                        <input
                        placeholder="20"
                        className="p-1 px-2 appearance-none outline-none w-full text-gray-800 "
                        id="weight" name="weight" type="weight"
                        onChange={handleChangeSimpan} value={inputTransaction.weight}
                        read
                    />{" "}
                    </div>
                    </div>
                </div>
            
                <div className="flex-1 flex flex-col md:flex-row">
                    <div className="w-full flex-1 mx-2">
                    {/* <p>{name}</p> */}
                    </div>
                </div>
            </div>

            <div className="flex flex-col md:flex-row">
            <div className="w-64 mx-2 font-bold h-6 mt-3 text-gray-800" />
            <div className="flex-1 flex flex-col md:flex-row bttn-simpan">
                <button type="submit" 

                className="text-sm  mx-2 w-32  focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer 
                    hover:bg-teal-700 hover:text-teal-100 
                    bg-teal-100 
                    text-teal-700 
                    border duration-200 ease-in-out 
                    border-teal-600 transition"
                >
                SIMPAN
                </button>
            </div>
            </div>

            </form>


            
        </div>
        </div>
    )
}

// form table
export const FormLogin = (props) => {
    const { state, handler } = useContext(GlobalContext)
    const {
        inputLoginProdi
    } = state 

    const {
        handleLoginProdi,
        handleChangeLoginProdi,
    } = handler

    return(
        <div className="" style={props.style}>
        <div className="mt-2">
            {/* username */}
            <form
            onSubmit={handleLoginProdi}
            >
            
            <div className="flex flex-col md:flex-row border-b border-gray-200 pb-4 mb-4">
            <div className="w-64 font-bold h-6 mx-2 mt-3 text-gray-800">
                Username
            </div>
            <div className="flex-1 flex flex-col md:flex-row">
                <div className="w-full flex-1 mx-2">
                <div className="my-2 p-1 bg-white flex border border-gray-200 rounded">
                    <input
                    placeholder="username.."
                    className="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                    onChange={handleChangeLoginProdi} value={inputLoginProdi.username}
                    id="username" name="username" type="username"
                    required
                    />{" "}
                </div>
                </div>
            </div>
            </div>

            <div className="flex flex-col md:flex-row border-b border-gray-200 pb-4 mb-4">
            <div className="w-64 font-bold h-6 mx-2 mt-3 text-gray-800">
                Password
            </div>
            <div className="flex-1 flex flex-col md:flex-row">
                <div className="w-full flex-1 mx-2">
                <div className="my-2 p-1 bg-white flex border border-gray-200 rounded">
                    <input
                    placeholder="password.."
                    className="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                    onChange={handleChangeLoginProdi} value={inputLoginProdi.password}
                    id="password" name="password" type="password"
                    required
                    />{" "}
                </div>
                </div>
            </div>
            </div>
            
            <div className="flex flex-col md:flex-row">
            <div className="w-64 mx-2 font-bold h-6 mt-3 text-gray-800" />
            <div className="flex-1 flex flex-col md:flex-row">
                <button type="submit" 
                className="text-sm  mx-2 w-32  focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer 
                    hover:bg-teal-700 hover:text-teal-100 
                    bg-teal-100 
                    text-teal-700 
                    border duration-200 ease-in-out 
                    border-teal-600 transition"
                >
                LOGIN
                </button>
            </div>
            </div>
            </form>
            
            
        </div>
        </div>
    )
}