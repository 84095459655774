import Cookies from "js-cookie"
import {  Navigate } from 'react-router-dom'
import { GlobalContext } from '../Context/context'
import { useContext } from 'react'

export const LoginRoute = (props) => {
    if (Cookies.get('token') !== undefined) {
      return <Navigate to={'/'} />
    } else if (Cookies.get('token') === undefined) {
      return props.children
    }
}

export const GuardRoute = (props) => {
    if (Cookies.get('token') === undefined) {
      return <Navigate to={'/login'} />
    } else if (Cookies.get('token') !== undefined) {
      return props.children
    }
}

export const LoginProdi =  () => {
    return (
        <>
        {/* component */}
                <div className="p-5">
                <div className="mt-2">
                    <div className="flex flex-col md:flex-row border-b border-gray-200 pb-4 mb-4">
                    <div className="w-64 font-bold h-6 mx-2 mt-3 text-gray-800">Full Name</div>
                    <div className="flex-1 flex flex-col md:flex-row">
                        <div className="w-full flex-1 mx-2">
                        <div className="my-2 p-1 bg-white flex border border-gray-200 rounded">
                            <input placeholder="First Name" className="p-1 px-2 appearance-none outline-none w-full text-gray-800 " /> </div>
                        </div>
                        <div className="w-full flex-1 mx-2">
                        <div className="my-2 p-1 bg-white flex border border-gray-200 rounded">
                            <input placeholder="Last Name.." className="p-1 px-2 appearance-none outline-none w-full text-gray-800 " /> </div>
                        </div>
                    </div>
                    </div>
                    <div className="flex flex-col md:flex-row pb-4 mb-4">
                    <div className="w-64 font-bold h-6 mx-2 mt-3 text-gray-800">Address
                        <div className="text-xs font-normal leading-none text-gray-500">Your billing address</div>
                    </div>
                    <div className="flex-1">
                        <div className="flex flex-col md:flex-row">
                        <select className="mx-2 flex-1 h-10 mt-2 form-select w-full">
                            <option value="US">US</option>
                            <option value="Italy">Italy</option>
                            <option value="Spain">Spain</option>
                            <option value="China">China</option>
                        </select>
                        <div className="w-full flex-1 mx-2">
                            <div className="my-2 p-1 bg-white flex border border-gray-200 rounded">
                            <input placeholder="State" className="p-1 px-2 appearance-none outline-none w-full text-gray-800 " /> </div>
                        </div>
                        <div className="w-full flex-1 mx-2">
                            <div className="my-2 p-1 bg-white flex border border-gray-200 rounded">
                            <input placeholder="Zip Code" className="p-1 px-2 appearance-none outline-none w-full text-gray-800 " /> </div>
                        </div>
                        </div>
                        <div className="m-2">
                        <div className="w-full flex-1">
                            <div className="my-2 p-1 bg-white flex border border-gray-200 rounded">
                            <input placeholder="Address" className="p-1 px-2 appearance-none outline-none w-full text-gray-800 " /> </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="flex flex-col md:flex-row">
                    <div className="w-64 mx-2 font-bold h-6 mt-3 text-gray-800" />
                    <div className="flex-1 flex flex-col md:flex-row">
                        <button className="text-sm  mx-2 w-32  focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer 
                            hover:bg-teal-700 hover:text-teal-100 
                            bg-teal-100 
                            text-teal-700 
                            border duration-200 ease-in-out 
                            border-teal-600 transition">Submit</button>
                    </div>
                    </div>
                </div>
                </div>
        </>
    )
}

export const LoginForm = () => {
    const { state, handler } = useContext(GlobalContext)
    const {
        inputLogin
    } = state 

    const {
        handleLogin,
        handleChangeLogin,
    } = handler

    return (
        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img className="mx-auto h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company" />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in to your account</h2>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" 
                method="POST"
                onSubmit={handleLogin}>

            {/* email */}
            <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email</label>
                <div className="mt-2">
                <input id="email" name="email" type="email" autoComplete="email" 
                onChange={handleChangeLogin} value={inputLogin.email}
                required 
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 
                    ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
            </div>

            {/* password */}
            <div>
                <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
                <div className="text-sm">
                    <a href="/forget-password" className="font-semibold text-indigo-600 hover:text-indigo-500">Forgot password?</a>
                </div>
                </div>
                <div className="mt-2">
                <input id="password" name="password" type="password" autoComplete="current-password" 
                    onChange={handleChangeLogin} value={inputLogin.password}
                    required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 
                    ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
            </div>

            <div>
                <button type="submit" className="flex w-full justify-center rounded-md bg-indigo-600 
                px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline 
                focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                
                >Sign in</button>
            </div>
            </form>
            <p className="mt-10 text-center text-sm text-gray-500">
                No Account ?
            <a href="/register" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"> Register</a>
            </p>
        </div>
        </div>

    )
}