import './App.css';
import {BrowserRouter,Route,Routes} from "react-router-dom"
import { GlobalProvider } from './Context/context';
import { Home } from './Home/home';
import { 
  Layout404,
  LayoutHomePage, 
  LayoutLogin,
  LayoutShipInfo,
  LayoutDashboard,
  LayoutInputTransaction,
  LayoutListTransactions,

  Layout, 
  LayoutBidding
   } from './Layout/layout';
import { LoginRoute, LoginForm, GuardRoute ,LoginProdi} from './Auth/login';
import { RegisterPage } from './Auth/register';
import { PasswordPage } from './Auth/forgetpassword';
import { Page404 } from './Misc/404';
import { JobDetail } from './Pages/jobdetail';
import { 
  DashboardShipSummary,
  DashboardSummary, 
  ListTransactions,
  ListVerifiedTransactions,
  ListVerifyTransaction,

  DashboardListData,
   DashboardAddData, 
   DashboardListRecommendation,
   DashboardListHistoryBimbingan,
   DashboardDosbing,
   ListStatusPengajuan,
   ListDaftarPengajuan,
   DashboardListStudentProdi,
  DashboardListMahasiswa,
  DashboardListDosen,
  DashboardKurikulum ,
  
   MapAPI} from './Pages/dashboard';


const App = () => {
  return (
    <>
    <BrowserRouter>
      <GlobalProvider>

        <Routes>

          {/* Homepage */}
          <Route path="/" element={
            <LayoutHomePage>
            </LayoutHomePage>
          }/>

          {/* TODO : delete */}
          <Route path="/map" element={
            <MapAPI/>
          }/>

          {/* LoginMenu */}
          <Route path="/login" element={
            <LayoutLogin>
            </LayoutLogin>
          }/>

          {/* shipInfoMenu */}
          <Route path="/ship-summary" element={
            <LayoutShipInfo>
              <DashboardShipSummary/>
            </LayoutShipInfo>
          }/>

          {/* inputTransaction */}
          <Route path="/input-transaction" element={
            <LayoutInputTransaction>
            </LayoutInputTransaction>
          }/>

          {/* listTransaction */}
          <Route path="/list-transaction" element={
            <LayoutListTransactions>
              <ListTransactions/>
            </LayoutListTransactions>
          }/>  
          
        
          {/* verifyTransaction */}
          <Route path="/verify-transaction" element={
            <LayoutListTransactions>
             <ListVerifyTransaction/>
          </LayoutListTransactions>
          }/>

          {/* verifiedTransaction */}
          <Route path="/verified-transactions" element={
             <LayoutListTransactions>
               <ListVerifiedTransactions/>
           </LayoutListTransactions>
          }/>

          {/* getChainData */}
          <Route path="/input-transaction" element={
            <LayoutInputTransaction>
            </LayoutInputTransaction>
          }/>

{/* TODO ---------------------- FARIS */}
          {/* recommendation-dosen-result */}
          <Route path="/recommendation-result" element={
            <LayoutInputTransaction>
              <DashboardListRecommendation/>
            </LayoutInputTransaction>
          }/>   

          {/* history bimbingan lecturer */}
          <Route path="/history-bimbingan/:id" element={
            <LayoutShipInfo>
              <DashboardListHistoryBimbingan/>
            </LayoutShipInfo>
          }/>   

          {/* add bidding */}
          <Route path="/bidding" element={
            <LayoutBidding>
              <DashboardDosbing/>
            </LayoutBidding>
          }/>   

          <Route path="/status-pengajuan" element={
            <LayoutShipInfo>
              <ListStatusPengajuan/>
            </LayoutShipInfo>
          }/>

          <Route path="/daftar-pengajuan" element={
            <LayoutShipInfo>
              <ListDaftarPengajuan/>
            </LayoutShipInfo>
          }/>

          <Route path="/student-list" element={
            <LayoutShipInfo>
              <DashboardListStudentProdi/>
            </LayoutShipInfo>
          }/>

          {/* ------------------ */}



          <Route path="/job-vacancy" element={<Home/>}/>
          <Route path="/job-vacancy/:id" element={<JobDetail/>}/>

          {/* <Route path="/dashboard" element={
            <GuardRoute>
              <LayoutDashboard>
                <DashboardSummary/>
              </LayoutDashboard>
            </GuardRoute>
          }/> */}

          <Route path="/dashboard/change-password" element={
            <GuardRoute>
              <LayoutDashboard>
                <PasswordPage/>
              </LayoutDashboard>
            </GuardRoute>
          }/>

          <Route path="/dashboard/list-job-vacancy" element={
            <GuardRoute>
              <LayoutDashboard>
                <DashboardListData/>
              </LayoutDashboard>
            </GuardRoute>
            
          }/>

          {/* student */}
          <Route path="/dashboard/list-mahasiswa-bimbingan" element={
            <GuardRoute>
              <LayoutDashboard>
                <DashboardListMahasiswa/>
              </LayoutDashboard>
            </GuardRoute>
          }/>

          {/* dosen */}
          {/* <Route path="/dashboard/list-dosen" element={
            <GuardRoute>
              <LayoutDashboard>
                <DashboardListDosen/>
              </LayoutDashboard>
            </GuardRoute>
          }/> */}

          {/* kurikulum */}
          <Route path="/dashboard/list-kurikulum" element={
            <GuardRoute>
              <LayoutDashboard>
                <DashboardKurikulum/>
              </LayoutDashboard>
            </GuardRoute>
          }/>

            
          <Route path="/dashboard/list-job-vacancy/create" element={
             <GuardRoute>
              <LayoutDashboard> 
                <DashboardAddData/>
              </LayoutDashboard>
             </GuardRoute>
            
          }/>

          <Route path="/dashboard/list-job-vacancy/edit/:id" element={
             <GuardRoute>
                <LayoutDashboard>
                  <DashboardAddData/>
                </LayoutDashboard>
             </GuardRoute>
          }/>

          {/* authentication & authorization */}
          <Route path="/login" element={
            <LoginRoute>
              <Layout>
                <LoginForm/>
              </Layout>
            </LoginRoute>
          }/>

          <Route path="/register" element={<RegisterPage/>}/>
          <Route path="/forget-password" element={<PasswordPage/>}/>

          {/* Not found pages */}
          <Route path="*" element={
          <Layout404>
            <Page404/>
          </Layout404>
          }/>

        </Routes>
      </GlobalProvider>
    </BrowserRouter>
    
  </>
  );
}

export default App;
