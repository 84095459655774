import axios from "axios";


const baseUrlSM = `https://backend.farisy.my.id`
const baseUrlLocal = `http://localhost:8000`

// getSummaryByUserID - get summary kuota
export const getSummaryByUserID = async (id) => {
    try {
        return await axios.get(`${baseUrlSM}/summary/${id}`)

    } catch (error) {
        return error.response
    }
}