import axios from "axios";


const baseUrlSM = `https://backend.farisy.my.id`
const baseUrlLocal = `http://localhost:8000`

// addTxPool - add transactionList 
export const addTxPool = async (payload) => {
    try {
        return await axios.post(`${baseUrlSM}/bchain-transaction`, payload)
    } catch (error) {
        return error.response
    }
}

// getTxPool - gets list of tx pool
export const getTxPool = async (payload) => {
    try {
       return await axios.get(`${baseUrlSM}/bchain-transaction`, payload)
    } catch (error) {
        return error.response
    }
}

// getBchain - gets bchain
export const getBchain = async (payload) => {
    try {
        return await axios.get(`${baseUrlSM}/bchain`, payload)
    } catch (error) {
        return error.response
    }
}


// getBchainMine - mining chain
export const getBchainMine = async (payload) => {
    try {
        return await axios.get(`${baseUrlSM}/bchain-mine`, payload)
    } catch (error) {
        return error.response
    }
}

