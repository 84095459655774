import axios from "axios";

const baseURLIoT = `http://192.168.1.6`
const baseURLBE = `https://localhost:8080`

// getsGPSData - gets GPS data
export const getsGPSData = async () => {
    try {
        const apps = await axios.get(`${baseURLIoT}/maps`)
        return apps
    } catch (error) {
        console.error(error)
        return error
    }
}

// getsVolumeData - gets volume data
export const getsVolumeData = async () => {
    try {
        const apps = await axios.get(`${baseURLIoT}/volume`)
        console.log("log volume:", apps)
        return apps
    } catch (error) {
        console.error(error)
        return error
    }
}

// postNotification - posts notification
export const postNotification = async () => {
    try {
        const apps = await axios.post(`${baseURLBE}/notification`)
        console.log("apps ;", apps)
        return apps
    } catch (error) {
        console.error(error)
        return error
    }
}