
// TruncateString - truncate desc
export const truncateString = (str, maxLength=40) => {
    if (str.length > maxLength) {
        return str.slice(0, maxLength) + "...";
    } 
    return str;
}

// ConvertRupiah - converts number into rupiah
export const convertRupiah = (angka)=>{
    if (angka <=0) {
        return 0
    }
    let numberString = angka.toString();
    let split = numberString.split('.');
    let rupiah = split[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    if (split.length > 1) {
        return `Rp. ${rupiah},${split[1]}`;
    }  
    return `Rp. ${rupiah}`;
}

// statusConvertion - converts opennes job
export const statusConvertion = (angka) => {
    if (angka == 1) {
        return "Open"
    }
    return "Closed"
}


export const mappingHargaAcuan = (jenisIkan) => {
    if (jenisIkan == "cumi") {
        return 10000
    }
    if (jenisIkan == "sotong") {
        return 12000
    }

    if (jenisIkan == "belanak") {
        return 15000
    }

    if (jenisIkan == "samge") {
        return 4000
    }
    if (jenisIkan == "golok-golok") {
        return 7000
    }

    if (jenisIkan == "lundu") {
        return 5000
    }

    return 0
}



export const nilaiProduksi = (berat, harga) => {
    return berat * harga
}

export const computeTotalWeight = (arr) => {
    let total = 0
    if (arr==undefined) {
        return 0
    }
    let data = arr?.transactions
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        total = total + element.value.weight
    }
    return total
}


export const computeTotalProduksi = (arr) => {
    let total = 0
    if (arr==undefined) {
        return 0
    }
    let data = arr?.transactions
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        total = total + (element.value.weight * mappingHargaAcuan(element.value.fish_type))
    }
    return total
}

export const computeTaxProduksi  = (total) => {
    return total * 0.1
}

export const generateUUID =() => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = (crypto.getRandomValues(new Uint8Array(1))[0] & 0x0f) | 0x00;
      if (c === 'y') {
        r = (r & 0x3) | 0x8;
      }
      return r.toString(16);
    });
}


export const extractNamaKapal = (arr) => {
    let name = ""
    if (arr==undefined) {
        return name
    }
    
    if (arr.transactions[0].value.ship_code == "") {
        return "ship A"
    }

    return arr.transactions[0].value.ship_code

}

export const extractTotalVolume = (arr) => {
    let total = 0
    if (arr==undefined) {
        return total
    }
    let data = arr.transactions
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        total = total + element.value.weight
    }
    return total
}

export const extractTotalPayment = (arr) => {
    let total = 0
    if (arr==undefined) {
        return total
    }
    let data = arr.transactions
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        total = total + (element.value.weight * mappingHargaAcuan(element.value.fish_type))
    }
    return total
    
}

export const extractTimeStamp = (arr) => {
    let total = 0
    if (arr==undefined) {
        return total
    }

    const date = new Date(arr.timestamp/1000000);

    const year = date.getUTCFullYear();
    const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
    const day = ('0' + date.getUTCDate()).slice(-2);

    const hours = ('0' + date.getUTCHours()).slice(-2);
    const minutes = ('0' + date.getUTCMinutes()).slice(-2);
    const seconds = ('0' + date.getUTCSeconds()).slice(-2);

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedDate + " " +formattedTime;
}