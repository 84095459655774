import axios from "axios";

const baseUrl = `https://api.smartcampus.my.id/public`
const baseUrlProdi = `https://backend.farisy.my.id`

// getLecturerData - get student list
export const getLecturerData = async () => {
    try {
        const apps = await axios.get(`${baseUrl}/lecturer`)
        return apps.data

    } catch (error) {
        console.error(error)
    }
}

// getLecturerData - get student list
export const getLecturerDataByID = async (id) => {
    try {
        let res = null
        res = await axios.get(`${baseUrl}/lecturer/${id}`)
        return res

    } catch (error) {
        return error.response
    }
}

// getLecturerData - get student list
export const getLecturerDataProdi = async () => {
    try {
        const apps = await axios.get(`${baseUrlProdi}/lecturer`,{mode:'cors'},{headers: {"Access-Control-Allow-Origin": "*"}})
        return apps.data.data

    } catch (error) {
        console.error(error)
    }
}

// getLecturerData - get student list
export const getLecturerDataProdiByID = async (id) => {
    try {
        const apps = await axios.get(`${baseUrlProdi}/lecturer/${id}`)
        return apps.data.data

    } catch (error) {
        console.error(error)
    }
}

// getLecturerData - get student list
export const getHistoryBimbingan = async (id) => {
    try {
        const apps = await axios.get(`${baseUrlProdi}/lecturer-bimbingan-history/${id}`)
        return apps.data.data

    } catch (error) {
        return error
    }
}

// createBidding
export const createBidding = async (payload) => {
    try {
        return await axios.post(`${baseUrlProdi}/bidding`, payload)
    } catch (error) {
        return error.response
    }
}





